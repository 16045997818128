import {
  DEFAULT_CANADA,
  DEFAULT_US,
  EMTY_STATE
} from "@/constants/common.constant"
import { getCountriesList, getStatesList } from "@/constants/countries"
import {
  ECollapseKey,
  EPaymentMethod,
  EShippingMethod
} from "@/enums/checkout.enum"
import { EOrderStatus } from "@/enums/quotesList.enum"
import {
  IBillingFormValues,
  ICheckoutForm,
  IDeliveryForm,
  IPaymentForm
} from "@/interfaces/checkout.interface"
import { IState } from "@/interfaces/common.interface"
import {
  IDeliveryTaxRes,
  useSubmitMutation,
  useSubmitOrderMutation,
  useUpdateInvoiceMutation
} from "@/services/apiDigifabster/order"
import { ICheckoutFormNew } from "@/services/apiDigifabster/payment"
import {
  GetTaxRateResponse,
  OrderDetailResponse,
  QuoteResponse,
  useGetTaxRateMutation
} from "@/services/apiDigifabster/quote"
import {
  ShippingRateResponse,
  useGetShippingRateMutation
} from "@/services/apiDigifabster/shipping"
import {
  useLazyGetCompanyQuery,
  useLazyUpdateBillingAddressCompanyQuery
} from "@/services/apiDigifabster/user"
import { RootState } from "@/store"
import { IQuoteStore, setQuoteShippingAndTaxPrice } from "@/store/quote"
import { IUserStore } from "@/store/user"
import { ILastAddress, UserInfoResponse } from "@/store/user/type"
import { getBiggestXObject } from "@/utils/arrayHelper"
import { getInvoiceHash } from "@/utils/stringHelper"
import { UploadFile } from "antd"
import { RcFile } from "antd/es/upload"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useCurrency } from "./useCurrency"

export interface IUploadData {
  orderId?: number
  files: Record<string, UploadFile>
  error?: string
}

export const useCheckoutFlow = () => {
  const [submit, { isLoading: isLoadingSubmitOrder }] = useSubmitMutation()
  const [submitQuote, { isLoading: isLoadingSubmitQuote }] =
    useSubmitOrderMutation()
  const [
    updateInvoice,
    { isLoading: isLoadingUpdateInvoice, isSuccess: isSuccessUpdate }
  ] = useUpdateInvoiceMutation()
  const [
    getShippingRate,
    { data: shippingRate, isLoading: getShippingRateLoading }
  ] = useGetShippingRateMutation()
  const [getTaxRate, { data: taxRate, isLoading: getTaxRateLoading }] =
    useGetTaxRateMutation()
  const [
    updateBillingAddressCompany,
    { isLoading: isUploadingNewBillingCompany }
  ] = useLazyUpdateBillingAddressCompanyQuery()
  const [getCompany] = useLazyGetCompanyQuery()

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { quoteDetail } = useSelector<RootState, IQuoteStore>((s) => s.quote)
  const { customerInfo } = useSelector<RootState, IUserStore>((s) => s.user)
  const { getCurrency } = useCurrency()
  const userInfoNew = customerInfo?.user
  const billingCompany = customerInfo?.company
  const country = quoteDetail?.country
  const currency = getCurrency(country)
  const company_billing_address = billingCompany?.billing_address
  const billing_address = userInfoNew?.billing_addresses
    ?.filter((e) => e.postcode !== "none")
    ?.sort((a, b) => {
      const dateA = new Date(a?.updated_at).getTime()
      const dateB = new Date(b?.updated_at).getTime()
      return dateB - dateA
    })?.[0]
  const last_billing_address = {
    ...userInfoNew?.last_billing_info,
    ...billing_address
  }

  const last_delivery_address = {
    ...userInfoNew?.last_delivery_info,
    ...userInfoNew?.last_delivery_address
  } as ILastAddress
  const deliveryData = userInfoNew?.delivery_addresses
  const delivery_tax_res = userInfoNew?.delivery_tax_res
  const DEFAULT_COUNTRY = country === "US" ? DEFAULT_US : DEFAULT_CANADA

  const getDeliveryAddressPrefill = async (
    quoteData?: OrderDetailResponse,
    meData?: UserInfoResponse,
    isRequiresReview?: boolean
  ) => {
    const delivery_exisited = meData?.delivery_addresses?.[0]
    const last_delivery_address = {
      ...meData?.last_delivery_info,
      ...meData?.last_delivery_address
    } as ILastAddress
    const shipping_address = quoteData?.shipping_address
    const prefillCountry = await findCountry(
      shipping_address?.country ||
        last_delivery_address?.country ||
        delivery_exisited?.country
    )
    const prefillSate = await findState(
      shipping_address?.state ||
        last_delivery_address?.state ||
        delivery_exisited?.state,
      Number(prefillCountry?.id)
    )
    const meMethod =
      meData?.last_shipping_method?.method?.toLowerCase() === "pick up"
        ? "pick_up"
        : meData?.last_shipping_method?.method?.toLowerCase()
    const quoteMethod =
      quoteData?.delivery_title?.toLowerCase() === "pick up"
        ? "pick_up"
        : quoteData?.delivery_title?.toLowerCase()
    const method = shipping_address
      ? (quoteMethod as EShippingMethod)
      : (meMethod as EShippingMethod)

    const additionalEmail =
      quoteData?.additional_billing_contacts?.join(", ") ||
      meData?.last_delivery_info?.additional_email ||
      ""
    const mail = isRequiresReview
      ? meData?.email || ""
      : shipping_address
      ? quoteData?.email || meData?.last_delivery_info?.email || ""
      : meData?.last_delivery_info?.email || quoteData?.email || ""
    const email = !!mail
      ? `${mail}${!!additionalEmail ? `, ${additionalEmail}` : ""}`
      : `${additionalEmail}`

    const name = isRequiresReview
      ? meData?.name || ""
      : shipping_address
      ? quoteData?.name || meData?.last_delivery_info?.name || ""
      : meData?.last_delivery_info?.name || quoteData?.name || ""

    const surname = isRequiresReview
      ? meData?.surname || ""
      : shipping_address
      ? quoteData?.surname || meData?.last_delivery_info?.surname || ""
      : meData?.last_delivery_info?.surname || quoteData?.surname || ""

    const phoneNumber = isRequiresReview
      ? meData?.phone_number || ""
      : shipping_address
      ? quoteData?.phone || meData?.last_delivery_info?.phone || ""
      : meData?.last_delivery_info?.phone || quoteData?.phone || ""
    const delivery_address = {
      name,
      surname,
      phoneNumber,
      email,
      city: isRequiresReview
        ? ""
        : shipping_address?.city ||
          meData?.last_delivery_info?.city ||
          delivery_exisited?.city ||
          "",
      country: isRequiresReview ? EMTY_STATE : prefillCountry,
      state: isRequiresReview ? EMTY_STATE : prefillSate,
      company: isRequiresReview
        ? ""
        : shipping_address?.company_name ||
          meData?.last_delivery_info?.company_name ||
          delivery_exisited?.company_name ||
          "",
      addressLine1: isRequiresReview
        ? ""
        : shipping_address?.street_address ||
          meData?.last_delivery_info?.street_address ||
          delivery_exisited?.street_address ||
          "",
      addressLine2: isRequiresReview
        ? ""
        : shipping_address?.apartment ||
          meData?.last_delivery_info?.apartment ||
          delivery_exisited?.apartment ||
          "",
      zipCode: isRequiresReview
        ? ""
        : shipping_address?.postcode ||
          meData?.last_delivery_info?.postcode ||
          delivery_exisited?.postcode ||
          ""
    }
    return { delivery_address, method }
  }

  const mapDeliveryTaxRes = (formData: ICheckoutForm) => {
    const deliveryAddress = deliveryData?.find(
      (e) =>
        formData?.delivery_address?.addressLine1 === e?.street_address &&
        formData?.delivery_address?.addressLine2 === e?.apartment &&
        formData?.delivery_address?.zipCode === e?.postcode &&
        formData?.delivery_address?.state?.iso2 === e?.state &&
        formData?.delivery_address?.city === e?.city &&
        formData?.delivery_address?.country.iso2 === e?.country &&
        formData?.delivery_address?.company === e?.company_name
    )

    const deliveryTaxRes: IDeliveryTaxRes[] = deliveryData
      ?.map((e) => {
        const res = delivery_tax_res?.find((f) => f?.address_id === e?.id)
        if (e?.id === deliveryAddress?.id) {
          return {
            address_id: e?.id || 0,
            rate: taxRate?.tax || 0,
            shippingRes: shippingRate
          } as IDeliveryTaxRes
        }

        return res as IDeliveryTaxRes
      })
      .filter((e) => e !== null && e !== undefined)
    return deliveryTaxRes
  }

  const getDeliveryTaxRes = (data: IDeliveryForm) => {
    const deliveryAddress = deliveryData?.find(
      (e) =>
        data?.addressLine1 === e?.street_address &&
        data?.addressLine2 === e?.apartment &&
        data?.zipCode === e?.postcode &&
        data?.state?.iso2 === e?.state &&
        data?.city === e?.city &&
        data?.country.iso2 === e?.country &&
        data?.company === e?.company_name
    )
    const deliveryTaxRes = delivery_tax_res?.find(
      (e) => e?.address_id === deliveryAddress?.id
    )
    return deliveryTaxRes
  }

  const findCountry = async (country?: string) => {
    if (!country) return DEFAULT_COUNTRY
    const countries = await getCountriesList()

    const prefillCountry = countries.find((item) => item.iso2 === country)
    const mapprefillCountry = {
      name: prefillCountry?.title || DEFAULT_COUNTRY.name,
      id: prefillCountry?.id || DEFAULT_COUNTRY.id,
      iso2: prefillCountry?.iso2 || DEFAULT_COUNTRY.iso2
    }
    return mapprefillCountry as IState
  }

  const findState = async (state?: string, countryId?: number) => {
    if (!state || !countryId) return EMTY_STATE
    const states = await getStatesList(countryId)
    const prefillState = states.find((item) => item.iso2 === state)
    const mapprefillState = {
      name: prefillState?.title || "",
      id: prefillState?.id || "",
      iso2: prefillState?.iso2 || ""
    }
    return mapprefillState as IState
  }

  const updateBillingForCompany = async (data: IPaymentForm) => {
    if (
      data.billing &&
      (!billingCompany.billing_address ||
        Object.keys(billingCompany.billing_address).length === 0) &&
      userInfoNew?.organization?.id
    ) {
      const payload = {
        companyId: userInfoNew?.organization?.id,
        billing_address: {
          ...data.billing,
          poFile: undefined // no need to save poFile in billing address
        }
      }
      await updateBillingAddressCompany(payload)
      await getCompany()
    }
  }

  const getSubmitOrderPayload = (
    payload: {
      notes?: string
      delivery_address: IDeliveryForm
      shipping_method: string
      shipping_carrier?: string
      shipping_note?: string
      po_number: string
      attached_po: UploadFile
      billing: IBillingFormValues
    },
    currentStatus: string
  ) => {
    const {
      delivery_address,
      shipping_method,
      shipping_carrier,
      shipping_note,
      notes
    } = payload
    const mappedPayload: ICheckoutFormNew = {
      payload: {
        name: delivery_address?.name,
        surname: delivery_address?.surname,
        phone: delivery_address?.phoneNumber,
        email: delivery_address?.email,
        notes: notes || "",
        currentStatus,
        shippingMethod: {
          method: shipping_method || "",
          carrier: shipping_carrier || "",
          note: shipping_note || ""
        },
        shippingAddress: {
          addressLine1: delivery_address?.addressLine1 || "",
          addressLine2: delivery_address?.addressLine2 || "",
          country: delivery_address?.country.iso2 || "",
          state: delivery_address?.state.iso2 || "",
          city: delivery_address?.city || "",
          postalCode: delivery_address?.zipCode || "",
          companyName: delivery_address?.company || ""
        },
        country: quoteDetail?.country
      },
      userId: userInfoNew?.id
    }
    return mappedPayload as ICheckoutFormNew
  }

  const checkIsPrefill = (
    meData?: UserInfoResponse,
    quoteData?: OrderDetailResponse
  ) => {
    return Boolean(
      (meData?.last_shipping_method &&
        Object.keys(meData?.last_shipping_method)?.length > 0) ||
        (meData?.last_delivery_info &&
          Object.keys(meData?.last_delivery_info)?.length > 0) ||
        (meData?.last_delivery_address &&
          Object.keys(meData?.last_delivery_address)?.length > 0) ||
        quoteData?.shipping_address ||
        quoteData?.self_notes ||
        quoteData?.name ||
        quoteData?.surname ||
        quoteData?.phone ||
        quoteData?.email ||
        quoteData?.additional_billing_contacts ||
        quoteData?.delivery_price
    )
  }

  const redirectInit = (
    currentQuote?: OrderDetailResponse,
    invoice_hash?: string,
    invoiceId?: string
  ) => {
    if (!currentQuote || !currentQuote.line_items || !currentQuote.status)
      return
    if (
      currentQuote?.status !== EOrderStatus.INITIAL &&
      currentQuote?.status !== EOrderStatus.FIRM_OFFER_SENT
    ) {
      const invoice_id = invoiceId || currentQuote?.main_invoice?.id
      const invoiceHash =
        invoice_hash || getInvoiceHash(currentQuote?.main_invoice?.pay_url)
      if (invoice_id && invoiceHash)
        return navigate(
          `/new-quote/checkout/${currentQuote?.id}/submitted/invoices/${invoice_id}/${invoiceHash}`
        )
      return navigate(`/new-quote`)
    }
  }

  const backFunction = (
    currentQuote?: QuoteResponse,
    invoice_hash?: string,
    invoiceId?: string
  ) => {
    if (
      currentQuote?.status === EOrderStatus.FIRM_OFFER_SENT &&
      invoiceId &&
      invoice_hash
    ) {
      if (invoiceId && invoice_hash) {
        navigate(`/quotes/invoices/${invoiceId}/${invoice_hash}`)
      } else {
        navigate(`/quotes/${currentQuote?.id}`)
      }
    } else {
      navigate(`/new-quote/specification/${currentQuote?.id}`)
    }
  }

  const getDeliveryType = {
    [EShippingMethod.LOCAL_PICKUP]: "Pick Up",
    [EShippingMethod.COLLECT]: "Collect",
    [EShippingMethod.DELIVERY]: "Delivery"
  }

  const calcDeliveryPriceIncludeTax = (shippingMethod: string) => {
    if (shippingMethod === EShippingMethod.DELIVERY) {
      return (
        (shippingRate?.[0]?.price || 0) +
        (shippingRate?.[0]?.price || 0) * (taxRate?.tax || 0)
      )
    } else {
      return 0
    }
  }

  const sumbitOrder = async (
    formData: ICheckoutForm,
    currentStatus: EOrderStatus,
    id: number,
    paymentMethod: EPaymentMethod,
    deliveryTaxRes: IDeliveryTaxRes[]
  ) => {
    const {
      delivery_address,
      billing,
      shipping_method,
      shipping_carrier,
      shipping_note,
      notes
    } = formData
    const deliveryPrice = calcDeliveryPriceIncludeTax(shipping_method)
    const splitEmails = delivery_address?.email
      .split(/\s*,\s*/)
      .map((email) => email.trim())
    const email = splitEmails[0] || ""
    const additional_email = splitEmails.slice(1).join(", ")

    const resSubmitOrder = await submit({
      orderId: id,
      arg: {
        userId: userInfoNew?.id,
        country: country,
        name: delivery_address?.name,
        surname: delivery_address?.surname,
        phone: delivery_address?.phoneNumber,
        email,
        notes: notes,
        shippingAddress: {
          addressLine1: delivery_address?.addressLine1,
          addressLine2: delivery_address?.addressLine2,
          country: delivery_address?.country.iso2 || "",
          state: delivery_address?.state.iso2 || "",
          city: delivery_address?.city,
          postalCode: delivery_address?.zipCode,
          companyName: delivery_address?.company,
          additionalEmail: additional_email
        },
        currentStatus,
        billingAddress:
          paymentMethod === EPaymentMethod.CREDIT_CARD
            ? undefined
            : {
                addressLine1: billing?.addressLine1,
                addressLine2: billing?.addressLine2,
                country: billing?.country.iso2 || "",
                state: billing?.state.iso2 || "",
                city: billing?.city,
                postalCode: billing?.zipCode,
                companyName: billing?.company,
                name: billing?.firstName,
                surname: billing?.lastName,
                phone: billing?.phoneNumber,
                email: billing?.accountPayEmail,
                poNumber: billing?.poNumber,
                taxNumber: billing?.taxNumber
              },
        dimensions: getBiggestXObject(quoteDetail),
        tax: taxRate?.tax,
        deliveryTaxRes,
        deliveryInfo: {
          deliveryPrice,
          deliveryTitle: getDeliveryType[shipping_method as EShippingMethod],
          deliveryType: shipping_method,
          shippingMethod: {
            method: shipping_method,
            carrier: shipping_carrier,
            note: shipping_note
          }
        }
      }
    })

    return resSubmitOrder
  }

  const sumbitQuote = async (
    formData: ICheckoutForm,
    currentStatus: EOrderStatus,
    id: number,
    paymentMethod: EPaymentMethod,
    deliveryTaxRes: IDeliveryTaxRes[]
  ) => {
    const {
      delivery_address,
      billing,
      shipping_method,
      shipping_carrier,
      shipping_note,
      notes
    } = formData
    const deliveryPrice = calcDeliveryPriceIncludeTax(shipping_method)
    const splitEmails = delivery_address?.email
      .split(/\s*,\s*/)
      .map((email) => email.trim())
    const email = splitEmails?.[0] || ""
    const additional_email = splitEmails?.slice(1).join(", ")

    const resSubmitOrder = await submitQuote({
      orderId: id,
      arg: {
        userId: userInfoNew?.id,
        country: country,
        name: delivery_address?.name,
        surname: delivery_address?.surname,
        phone: delivery_address?.phoneNumber,
        email,
        notes: notes,
        shippingAddress: {
          addressLine1: delivery_address?.addressLine1,
          addressLine2: delivery_address?.addressLine2,
          country: delivery_address?.country.iso2 || "",
          state: delivery_address?.state.iso2 || "",
          city: delivery_address?.city,
          postalCode: delivery_address?.zipCode,
          companyName: delivery_address?.company,
          additionalEmail: additional_email
        },
        currentStatus,
        billingAddress:
          paymentMethod === EPaymentMethod.CREDIT_CARD
            ? undefined
            : {
                addressLine1: billing?.addressLine1,
                addressLine2: billing?.addressLine2,
                country: billing?.country.iso2 || "",
                state: billing?.state.iso2 || "",
                city: billing?.city,
                postalCode: billing?.zipCode,
                companyName: billing?.company,
                name: billing?.firstName,
                surname: billing?.lastName,
                phone: billing?.phoneNumber,
                email: billing?.accountPayEmail,
                poNumber: billing?.poNumber,
                taxNumber: billing?.taxNumber
              },
        dimensions: getBiggestXObject(quoteDetail),
        tax: taxRate?.tax,
        deliveryTaxRes,
        deliveryInfo: {
          deliveryPrice,
          deliveryTitle: getDeliveryType[shipping_method as EShippingMethod],
          deliveryType: shipping_method,
          shippingMethod: {
            method: shipping_method,
            carrier: shipping_carrier,
            note: shipping_note
          }
        }
      }
    })

    return resSubmitOrder
  }

  const updatePO = async (
    invoiceId: number,
    invoiceHash: string,
    attached_po: UploadFile<any>,
    po_number?: string
  ) => {
    const file = attached_po.originFileObj
    const uploadPayload = new FormData()
    if (file) uploadPayload.append("attached_po", file as RcFile, file?.name)
    if (po_number) uploadPayload.append("po_number", po_number)

    const payloadUpdateInvoice = {
      invoice_id: invoiceId,
      invoice_hash: invoiceHash,
      arg: uploadPayload
    }
    await updateInvoice(payloadUpdateInvoice)
  }

  const getShippingRateAndTax = async (
    orderId: number,
    data: IDeliveryForm,
    country?: string
  ) => {
    const results = await Promise.allSettled([
      getShippingRate({
        orderId,
        arg: {
          country: data.country.iso2 || "",
          state: data.state.iso2 || "",
          postalCode: data.zipCode,
          city: data.city,
          userCountry: country || quoteDetail?.country,
          dimensions: getBiggestXObject(quoteDetail)
        }
      }),
      getTaxRate({
        orderId,
        arg: {
          country: data.country.iso2 || "",
          state: data.state.iso2 || "",
          postalCode: data.zipCode
        }
      })
    ])
    const shippingRes =
      results[0].status === "fulfilled" ? results[0].value : null
    const taxRes = results[1].status === "fulfilled" ? results[1].value : null
    return { shippingRes, taxRes }
  }

  const handlePrefillDeliveryPrice = ({
    formData,
    method,
    _shippingRateRes,
    taxRateRes
  }: {
    formData: ICheckoutForm
    method: EShippingMethod
    _shippingRateRes: ShippingRateResponse[]
    taxRateRes?: GetTaxRateResponse
  }) => {
    const isPrefill =
      !!formData?.shipping_method || method !== EShippingMethod.DELIVERY
    if (!isPrefill) {
      dispatch(
        setQuoteShippingAndTaxPrice({
          shippingPrice: _shippingRateRes[0]?.price || 0,
          taxRate: taxRateRes?.tax
        })
      )
    } else {
      dispatch(
        setQuoteShippingAndTaxPrice({
          shippingPrice: 0,
          taxRate: taxRateRes?.tax
        })
      )
    }
  }

  const beforeUnloadHandler =
    (formData: any, isSubmitting: boolean, isLogout: boolean) =>
    (event: BeforeUnloadEvent) => {
      if (isLogout) return
      if (Object.keys(formData).length > 0 && !isSubmitting) {
        const confirmationMessage =
          "Are you sure you want to leave? All progress of the current order will be lost."
        event.returnValue = confirmationMessage
        return confirmationMessage
      }
    }

  const disabledSubmit = (
    isRequiresReview: boolean,
    doneKeysCheckout: number[]
  ) => {
    const requiredKeys = [
      ECollapseKey.ORDER_REVIEW,
      ECollapseKey.DELIVERY_ADDRESS,
      ECollapseKey.SHIPPING_METHOD
    ]
    const isEnableKeys = isRequiresReview
      ? requiredKeys.filter((item) => item === ECollapseKey.ORDER_REVIEW)
      : requiredKeys
    return (
      !isEnableKeys.every((key) => doneKeysCheckout.includes(key)) ||
      getTaxRateLoading ||
      getShippingRateLoading
    )
  }

  const goToPaymentPage = async (
    orderId: number,
    clientSecret: string,
    invoice_hash?: string,
    invoiceId?: string
  ) => {
    if (invoiceId && invoice_hash)
      return navigate(
        `/stripe-page/${clientSecret}/${orderId}/${invoiceId}/${invoice_hash}`
      )
    return navigate(`/stripe-page/${clientSecret}/${orderId}`)
  }

  return {
    sumbitOrder,
    sumbitQuote,
    updatePO,
    getShippingRateAndTax,
    beforeUnloadHandler,
    redirectInit,
    backFunction,
    disabledSubmit,
    goToPaymentPage,
    getDeliveryAddressPrefill,
    getDeliveryTaxRes,
    mapDeliveryTaxRes,
    handlePrefillDeliveryPrice,
    updateBillingForCompany,
    getSubmitOrderPayload,
    checkIsPrefill,
    country,
    currency,
    company_billing_address,
    billing_address,
    last_billing_address,
    last_delivery_address,
    deliveryData,
    delivery_tax_res,
    isUploadingNewBillingCompany,
    isLoadingSubmitOrder,
    isSuccessUpdate,
    isLoadingUpdateInvoice,
    getTaxRateLoading,
    getShippingRateLoading,
    isLoadingSubmitQuote
  }
}
