import "./CreateQuote.scss"
import RootContainer from "@/components/RootContainer/RootContainer"
import Uploading from "./components/Uploading/Uploading"
import { Button, CustomUpload, ModalSelectUnit } from "@/components"
import { useRef, useState } from "react"
import { UploadFile } from "antd"
import { protectIcon, questionIcon } from "@/assets"
import { ButtonHierarchy, Size } from "@/enums/common.enum"
import ManualRequestDrawer from "./components/ManualRequestDrawer/ManualRequestDrawer"
import { useNavigate } from "react-router-dom"
import AbandonedListCart from "./components/AbandonedListCart/AbandonedListCart"
import { EUnit } from "@/enums/unit.enum"
import { useUploadModels } from "@/hooks/useUploadModels"
import * as toast from "@/utils/Toast"
import { useDispatch } from "react-redux"
import { cleanState, setNewQuoteUploaded } from "@/store/quote"
import ModalSupportFiles from "@/components/ModalSupportFiles/ModalSupportFiles"
import { fileSupportPattern } from "@/constants/common.constant"

export default function NewQuotePage(): JSX.Element {
  const [isLoading, setIsLoading] = useState(false)
  const [isUploadFailed, setIsUploadFailed] = useState(false)

  const [openDrawer, setOpenDrawer] = useState(false)
  const filesRef = useRef<UploadFile[]>([])
  const [open, setOpen] = useState(false)
  const [selectUnit, setSelectUnit] = useState<EUnit>(EUnit.MM)
  const [isOpenModalFile, setIsOpenModalFile] = useState<boolean>(false)

  const dispatch = useDispatch()

  const navigate = useNavigate()

  const { uploadModels } = useUploadModels()

  const showDrawer = () => {
    setOpenDrawer(true)
  }

  const onCloseDrawer = () => {
    setOpenDrawer(false)
  }

  const navigateToSpecification = async (unit?: EUnit) => {
    const files = filesRef.current
    const isSupportFile = files.every((e) => fileSupportPattern.test(e.name))
    if (!isSupportFile && !isOpenModalFile) {
      return
    }
    try {
      setIsLoading(true)
      dispatch(cleanState())
      const {
        orderId,
        error,
        files: uploadedFiles
      } = await uploadModels(files, unit, {
        onlyUploadFiles: true
      })
      if (error) {
        throw new Error(error)
      }
      dispatch(setNewQuoteUploaded(Object.values(uploadedFiles)))
      navigate(`/new-quote/specification/${orderId}`, { replace: true })
    } catch (err: any) {
      setIsUploadFailed(true)
      toast.showError(err.message || err)
    } finally {
      setIsLoading(false)
    }
  }

  const handleAllFilesDone = async (files: UploadFile[]) => {
    const isContainStepFile = files.some((file) => {
      return /(stp|step|igs|iges)$/i.test(file.name)
    })
    const isContainAllStepFile = files.every((file) => {
      return /(stp|step|igs|iges)$/i.test(file.name)
    })

    const isSupportFile = files.every((file) =>
      fileSupportPattern.test(file.name)
    )

    filesRef.current = files
    if (isContainAllStepFile || (isContainStepFile && files.length === 1)) {
      navigateToSpecification()
    } else {
      if (!isSupportFile) {
        setIsOpenModalFile(true)
        return
      }
      setOpen(true)
    }
  }

  const handleSelectedUnit = async () => {
    setOpen(false)
    navigateToSpecification(selectUnit)
  }

  const handleLeavePage = async () => {
    setIsOpenModalFile(false)
    navigateToSpecification(selectUnit)
  }

  return (
    <RootContainer headerText={"New Instant Quote"}>
      <div className="new-quote-container">
        {!isLoading && !isUploadFailed ? (
          <div className="create-quote">
            <div className="content-container">
              <div className="content-box">
                <CustomUpload onAllDone={handleAllFilesDone} />
                <div className="upload-footer">
                  <img src={protectIcon} />
                  <p>
                    Privacy: All your uploaded files are secure and
                    confidential. Read our Privacy policy
                  </p>
                </div>
                <p className="subtitle-upload">
                  Uploading weapons or parts subject to export control
                  regulations is strictly prohibited and a violation of our
                  terms of use
                </p>
              </div>
              <div className="manual-request-box">
                <div className="manual-request-box-left">
                  <div>
                    <img src={questionIcon} />
                  </div>
                  <div className="text-box">
                    <p>Need help with your project?</p>
                    <p>
                      Request a manual review of your project part from our team
                      of engineers and designers.
                    </p>
                  </div>
                </div>
                <div>
                  <Button
                    hierarchy={ButtonHierarchy.LINK}
                    onClick={showDrawer}
                    customSize={Size.MEDIUM}
                  >
                    Talk to us
                  </Button>
                </div>
                <ManualRequestDrawer
                  open={openDrawer}
                  onClose={onCloseDrawer}
                />
              </div>
            </div>

            <div className="footer-container">
              <AbandonedListCart></AbandonedListCart>
            </div>
          </div>
        ) : (
          <Uploading
            onBackToHome={() => {
              window.location.href = "/"
            }}
            mode={isUploadFailed ? "fail" : "pending"}
          />
        )}
      </div>
      <ModalSelectUnit
        openModal={open}
        closeModal={() => setOpen(false)}
        onChangeUnit={(id) => setSelectUnit(id)}
        onSelectedUnit={handleSelectedUnit}
        unit={selectUnit}
      ></ModalSelectUnit>
      <ModalSupportFiles
        openModal={isOpenModalFile}
        closeModal={() => setIsOpenModalFile(false)}
        onLeave={handleLeavePage}
      ></ModalSupportFiles>
    </RootContainer>
  )
}
