import { useEffect, useState } from "react"
import ShippingContainer from "./components/ShippingContainer/ShippingContainer"
import ShippingForm from "./components/ShippingForm/ShippingForm"
import { RootState } from "@/store"
import { IUserStore } from "@/store/user"
import { useSelector } from "react-redux"
import ShippingDetail from "./components/ShippingDetail/ShippingDetail"
import { Button } from "@/components"
import { ButtonHierarchy } from "@/enums/common.enum"

function ShippingPage() {
  const { userInfoNew } = useSelector<RootState, IUserStore>((s) => s.user)
  const hasDeliveryAddresses = !!userInfoNew.delivery_addresses.length

  const [isEdit, setIsEdit] = useState<boolean>(() => !hasDeliveryAddresses)
  return (
    <ShippingContainer>
      {isEdit ? (
        <ShippingForm setEdit={setIsEdit}></ShippingForm>
      ) : (
        <ShippingDetail></ShippingDetail>
      )}
      {!isEdit && (
        <div className="shipping-container-btn">
          <Button
            hierarchy={ButtonHierarchy.OUTLINE}
            onClick={() => setIsEdit(true)}
            customClassName="shipping-container-btn-edit"
          >
            Edit
          </Button>
        </div>
      )}
    </ShippingContainer>
  )
}

export default ShippingPage
