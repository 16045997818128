import {
  Button,
  CustomBadge,
  FormField,
  RadioButton,
  TextField
} from "@/components"
import { Radio, RadioChangeEvent, Upload, UploadFile, UploadProps } from "antd"
import React, { useEffect, useState } from "react"
import "./Payment.scss"
import { ECollapseKey, EPaymentMethod } from "@/enums/checkout.enum"
import { Controller, useFormContext } from "react-hook-form"
import {
  IBillingFormValues,
  ICheckoutForm,
  IPaymentForm
} from "@/interfaces/checkout.interface"
import { errorStatus } from "@/constants/common.constant"
import BillingInfoEditForm from "./components/BillingInfoEditForm/BillingInfoEditForm"
import {
  BadgeColor,
  BadgeType,
  ButtonHierarchy,
  Size,
  Type
} from "@/enums/common.enum"
import { PDFIcon, uploadPDFIcon } from "@/assets"
import { CloseOutlined } from "@ant-design/icons"
import { RootState } from "@/store"
import { IUserStore } from "@/store/user"
import { useSelector } from "react-redux"
import _ from "lodash"
import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import stripeConfig from "@/config/stripe"
import { ILastAddress } from "@/store/user/type"
interface IPaymentProps {
  disabled: boolean
  submitText: string
  onSubmit: (data?: IPaymentForm) => void
  prefillData?: ICheckoutForm
  handleChangePaymentMethod: (e: RadioChangeEvent) => void
  value: string
  handlePreStep: () => void
  last_billing_address: ILastAddress
  setRemovedKey: React.Dispatch<React.SetStateAction<ECollapseKey | undefined>>
}
const Payment: React.FC<IPaymentProps> = ({
  submitText,
  disabled,
  onSubmit,
  prefillData,
  handleChangePaymentMethod,
  value,
  handlePreStep,
  last_billing_address,
  setRemovedKey
}) => {
  const [fileList, setFileList] = useState<UploadFile[]>([])

  const { control, formState, watch, setValue, setError } =
    useFormContext<IBillingFormValues>()

  const { errors, defaultValues, isSubmitting } = formState
  const formData = watch()

  useEffect(() => {
    if (value !== EPaymentMethod.CREDIT_CARD) {
      const data = _.omitBy(formData, _.isUndefined)
      if (
        !isSubmitting &&
        (Object.keys(errors).length || !_.isEqual(data, defaultValues))
      ) {
        setRemovedKey(ECollapseKey.PAYMENT)
      }
    }
  }, [formData])

  const onSubmitDataBilling = (data: IBillingFormValues) => {
    const mappedData: IPaymentForm = {
      billing: { ...data, poNumber: watch("poNumber") },
      poFile: fileList[0]
    }
    onSubmit(mappedData)
  }

  const handleChangeFile: UploadProps["onChange"] = ({ file }) => {
    setFileList([file])
    const isPDF = file.type === "application/pdf"

    if (!isPDF)
      setError("poFile", {
        type: "isValid",
        message: "Only PDF file is allowed."
      })

    setValue("poFile", [file], { shouldValidate: true })
  }

  const handleRemoveFile = (file: UploadFile) => {
    setFileList((prev) => prev.filter((item) => item.uid !== file.uid))
    setValue("poFile", [], {
      shouldValidate: true
    })
  }

  const getBorder = (value: string) => {
    if (value !== EPaymentMethod.CREDIT_CARD) return "border-payment"
  }

  const textUpload = !!fileList.length ? "Replace PDF" : "Upload PDF"
  const stripePromise = loadStripe(stripeConfig.apiKey || "")

  const stripe = useStripe()
  const elements = useElements()

  return (
    <>
      <div className={`payment-method-wrapper ${getBorder(value)}`}>
        <p className="payment-method-title">Payment Method</p>
        {value === EPaymentMethod.PURCHASE_ORDER && (
          <p className="payment-method-text-field">Required Field *</p>
        )}
        <div
          className={
            value === EPaymentMethod.CREDIT_CARD ? "" : "payment-method"
          }
        >
          <div className="payment-method-left">
            <Radio.Group value={value} onChange={handleChangePaymentMethod}>
              <div className="payment-method-left-radio">
                <div>
                  <RadioButton value={EPaymentMethod.CREDIT_CARD}>
                    Credit Card
                  </RadioButton>
                  {value === EPaymentMethod.CREDIT_CARD && (
                    <div className="stripe-wrapper">
                      <p className="title">CARD INFORMATION</p>
                      <PaymentElement></PaymentElement>
                      <div className="footer">
                        <div className="left">
                          <p>Powered by Stripe</p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div>
                  <RadioButton value={EPaymentMethod.PURCHASE_ORDER}>
                    Purchase Order
                  </RadioButton>
                </div>
              </div>
            </Radio.Group>
          </div>
          <div className="payment-method-right">
            {value === EPaymentMethod.CREDIT_CARD ? (
              <></>
            ) : (
              <>
                <FormField
                  textLabel="PO number *"
                  errorText={errors.poNumber?.message}
                  clasName="payment-method-right-po"
                >
                  <Controller
                    control={control}
                    name="poNumber"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        placeholder="Input number"
                        status={errorStatus(errors.poNumber)}
                      ></TextField>
                    )}
                  ></Controller>
                </FormField>
                <FormField
                  textLabel="Purchase Order Document *"
                  errorText={errors.poFile?.message}
                >
                  <Controller
                    control={control}
                    name="poNumber"
                    render={({ field }) => (
                      <>
                        <div
                          className={`payment-method-right-content ${
                            errors.poFile?.message
                              ? "payment-method-right-content-error"
                              : ""
                          }`}
                        >
                          {fileList?.length > 0 ? (
                            <CustomBadge
                              content={""}
                              size={Size.MEDIUM}
                              type={BadgeType.TONAL}
                              color={BadgeColor.ROLE}
                            >
                              <div
                                key={fileList[0].uid}
                                className="item-render-pdf"
                              >
                                <img src={PDFIcon} alt="" />
                                <p>{fileList[0].name}</p>
                                <div className="icon-box">
                                  <CloseOutlined
                                    onClick={() =>
                                      handleRemoveFile(fileList[0])
                                    }
                                    className="icon-box-render"
                                  />
                                </div>
                              </div>
                            </CustomBadge>
                          ) : (
                            <p></p>
                          )}
                          <Upload
                            accept="*/*"
                            fileList={fileList}
                            onChange={handleChangeFile}
                            showUploadList={false}
                          >
                            <Button
                              customSize={Size.SMALL}
                              hierarchy={ButtonHierarchy.LINK}
                              customClassName="upload-btn"
                            >
                              <img src={uploadPDFIcon} alt="" />
                              <p className="title-upload">{textUpload}</p>
                            </Button>
                          </Upload>
                        </div>
                      </>
                    )}
                  ></Controller>
                </FormField>
              </>
            )}
          </div>
        </div>
      </div>
      {value !== EPaymentMethod.CREDIT_CARD ? (
        <div className="billing-info">
          <div className="billing-info-box">
            <BillingInfoEditForm
              last_billing_address={last_billing_address}
              onSubmit={onSubmitDataBilling}
              handlePreStep={handlePreStep}
              prefillData={prefillData}
              disabled={disabled}
              submitText={submitText}
            />
          </div>
        </div>
      ) : (
        <div className="collapse-footer">
          <Button
            customType={Type.NEUTRAL}
            hierarchy={ButtonHierarchy.OUTLINE}
            onClick={handlePreStep}
          >
            Previous Step
          </Button>
          <Button disabled={disabled} onClick={() => onSubmit()}>
            {submitText}
          </Button>
        </div>
      )}
    </>
  )
}

export default Payment
