import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"

import { ButtonHierarchy, Size } from "@/enums/common.enum"
import "./SkeletonProductItem.scss"
import "../ProductItem.scss"
import { Button, CustomCheckbox } from "@/components"
import { EModelAnalysisStatus } from "@/store/product"
import { useCurrency } from "@/hooks"
import SingleSkeleton from "./SingleSkeleton"
import { tagStatusClass } from "@/pages/SpecificationPage/components/ProductItem/ProductItem"
import { UploadFile } from "antd"
import { DeleteOutlined } from "@ant-design/icons"
import { DotLottieReact } from "@lottiefiles/dotlottie-react"
import LoadingAnimation from "@/assets/lottie/AnimationPriceLoading.json"

export const SkeletonProductItem = ({
  file,
  onDelete,
  onOpenManualRequest
}: {
  file: UploadFile
  onDelete: () => void
  onOpenManualRequest: () => void
}) => {
  const { currency } = useCurrency()

  return (
    <div className="model-item">
      <div className="col">
        <div className="product-tag">
          <p>
            <img
              width={16}
              height={16}
              src={
                tagStatusClass[
                  file.status === "uploading"
                    ? EModelAnalysisStatus.LOADING
                    : EModelAnalysisStatus.ERROR
                ]?.icon
              }
              alt=""
            />
            <span>
              {file.status === "uploading"
                ? tagStatusClass[EModelAnalysisStatus.LOADING].text
                : "Upload Failed"}
            </span>
          </p>
          <div
            className={`corner-bottom-right-bevel ${
              tagStatusClass[
                file.status === "uploading"
                  ? EModelAnalysisStatus.LOADING
                  : EModelAnalysisStatus.ERROR
              ].className
            }`}
          />{" "}
        </div>
        <div className="skeleton-container">
          <div className="col-1">
            <CustomCheckbox
              style={{ visibility: "hidden" }}
              disabled
              size={Size.MEDIUM}
            />
            <div className="product-viewer">
              <Skeleton width={83} height={72} />
            </div>
            <div className="col-1-part">
              <SingleSkeleton />
              <SingleSkeleton />
              <SingleSkeleton />
              <SingleSkeleton />
            </div>
          </div>

          <div className="col-4">
            <SingleSkeleton />
            <SingleSkeleton />
            <SingleSkeleton />
            <SingleSkeleton />
          </div>
          <div className="col-5">
            <Skeleton height={52} />
          </div>
          <div className="col-6">
            <div className="col-6-text">
              <DotLottieReact
                data={LoadingAnimation}
                loop
                autoplay
                style={{ width: 51, height: 51 }}
              />
            </div>
            <div className="col-6-action">
              <div />
              {file.status === "error" && (
                <Button hierarchy={ButtonHierarchy.LINK} onClick={onDelete}>
                  <DeleteOutlined />
                </Button>
              )}
            </div>
          </div>
        </div>
        <div
          className="footer"
          style={{ display: file?.status === "error" ? "block" : "none" }}
        >
          <span className="error-message">
            {file.error && (
              <>
                {file.error || ""}
                <>
                  <span onClick={() => onOpenManualRequest()}>
                    request manual review
                  </span>
                </>
              </>
            )}
          </span>

          <div></div>
        </div>
      </div>
    </div>
  )
}
