export enum EManufacturingProcess {
  SLS = 1,
  MJF = 8,
  SLA = 7,
  FDM = 2,
  DP = 4,
  DMLS = 3
}

export enum EMeasurement {
  MM = 1,
  CM = 2,
  DM = 3
}
