export enum EOrderStatus {
  CREATED = "created",
  WAITING_FOR_REVIEW = "waiting_for_review",
  FIRM_OFFER_SENT = "firm_offer_sent",
  PLACED = "placed",
  PO_PROVIDED = "po_provided",
  WAITING_FOR_PAYMENT = "waiting_for_payment",
  ON_HOLD = "on_hold",
  FREE_FOR_MANUFACTURING = "free_for_manufacturing",
  PLANNING_CONFIRMED = "planning_confirmed",
  MANUFACTURING = "manufacturing",
  POST_PRODUCTION = "post_production",
  RE_MANUFACTURING = "re_manufacturing",
  READY_FOR_SHIPPING = "ready_for_shipping",
  SHIPPED = "shipped",
  READY_FOR_PICKUP = "ready_for_pickup",
  DELIVERED = "delivered",
  PO_PAYMNET_DUE = "po_payment_due",
  CANCEL_BY_USER = "cancelled_by_user",
  CANCEL_BY_ADMIN = "cancelled_by_admin",
  INITIAL = "initial"
}
