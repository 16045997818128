import { ICheckoutForm, IPaymentForm } from "@/interfaces/checkout.interface"
import { api } from "../apiInstance"

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPaymentLink: build.query<GetPaymentLinkResponse, GetPaymentLinkPayload>({
      query: (queryArg) => ({
        url: `/api/dgfc/order/${queryArg.orderId}/payment/`,
        method: "GET"
      })
    }),
    updateIntentPayment: build.mutation<
      PaymentIntentResponse,
      PaymentIntentPayload
    >({
      query: (queryArg) => ({
        url: `/api/dgfc/order/${queryArg.quoteId}/payment/update/${queryArg.clientSecret}/`,
        method: "POST",
        body: queryArg.payloadOrder
      })
    })
  }),
  overrideExisting: false
})
export { injectedRtkApi as shippingService }

export interface GetPaymentLinkPayload {
  orderId: number
}

export interface ICheckoutFormNew {
  payload: {
    name: string
    surname: string
    phone: string
    email: string
    notes: string
    currentStatus: string
    shippingMethod: {
      method: string
      carrier: string
      note: string
    }
    shippingAddress?: {
      addressLine1: string
      addressLine2: string
      country: string
      state: string
      city: string
      postalCode: string
      companyName: string
    }
    country?: string
  }
  userId: number
}

export interface PaymentIntentPayload {
  quoteId: number
  clientSecret: string
  payloadOrder: ICheckoutFormNew
}

export interface GetPaymentLinkResponse {
  url: string
  clientSecret: string
}

export interface PaymentIntentResponse {
  id: string
  object: string
  amount: number
  amount_capturable: number
  amount_details: {
    tip: Record<string, any>
  }
  amount_received: number
  application: string | null
  application_fee_amount: number | null
  automatic_payment_methods: {
    enabled: boolean
  }
  canceled_at: number | null
  cancellation_reason: string | null
  capture_method: string
  client_secret: string
  confirmation_method: string
  created: number
  currency: string
  customer: string | null
  description: string | null
  invoice: string | null
  last_payment_error: string | null
  latest_charge: string | null
  livemode: boolean
  metadata: {
    order_id: string
    [key: string]: any
  }
  next_action: string | null
  on_behalf_of: string | null
  payment_method: string | null
  payment_method_options: {
    card: {
      installments: string | null
      mandate_options: string | null
      network: string | null
      request_three_d_secure: string
    }
    link: {
      persistent_token: string | null
    }
  }
  payment_method_types: string[]
  processing: string | null
  receipt_email: string | null
  review: string | null
  setup_future_usage: string | null
  shipping: string | null
  source: string | null
  statement_descriptor: string | null
  statement_descriptor_suffix: string | null
  status: string
  transfer_data: string | null
  transfer_group: string | null
}

export const { useLazyGetPaymentLinkQuery, useUpdateIntentPaymentMutation } =
  injectedRtkApi
