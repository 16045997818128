import { RootState } from "@/store"
import { IUserStore } from "@/store/user"
import { useSelector } from "react-redux"
import "./BillingAddressDetail.scss"
import { getCountriesList, getStatesList } from "@/constants/countries"
import { useEffect, useState } from "react"

function BillingAddressDetail() {
  const { userInfoNew } = useSelector<RootState, IUserStore>((s) => s.user)
  const address = userInfoNew?.billing_addresses?.[0]

  const [countryName, setCountryName] = useState<string | null>(null)
  const [stateName, setStateName] = useState<string | null>(null)

  useEffect(() => {
    const fetchLocationNames = async () => {
      const countries = await getCountriesList()

      if (address?.country) {
        const country = countries.find((item) => item.iso2 === address.country)
        setCountryName(country?.title || address.country)

        if (address?.state && country?.id) {
          const states = await getStatesList(Number(country.id))
          const state = states.find((item) => item.iso2 === address.state)
          setStateName(state?.title || address.state)
        }
      }
    }

    fetchLocationNames()
  }, [address])

  return (
    <div className="shipping-detail">
      <p className="shipping-detail-title">Details</p>
      <div className="shipping-detail-content">
        <p>{address?.full_address}</p>
        <p>{address?.postcode}</p>
        <p>
          {address?.street_address}, {address?.apartment}
        </p>
        <p>
          {countryName}, {stateName}
        </p>
        <p>{userInfoNew?.last_billing_info?.email}</p>
        <p>{userInfoNew?.last_billing_info?.phone}</p>
        <p>
          {userInfoNew?.last_billing_info?.name}{" "}
          {userInfoNew?.last_billing_info?.surname}
        </p>
      </div>
    </div>
  )
}

export default BillingAddressDetail
