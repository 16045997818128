import { EManufacturingProcess } from "@/enums/editSpecifications.enum"

export const TOLERANCE_TEXT = {
  [EManufacturingProcess.SLS]: {
    text: "Most parts will be printed within ±0.25 mm or ±0.25%, whichever is greater.",
    url: "https://forgelabs.com/sls-design-guide/"
  },
  [EManufacturingProcess.MJF]: {
    text: "Most parts will be printed within ±0.3 mm or ±0.3%, whichever is greater.",
    url: "https://forgelabs.com/mjf-design-guide/"
  },
  [EManufacturingProcess.SLA]: {
    text: "Most parts will be printed within ±0.25 mm or ±0.25% (whichever is greater)",
    url: "https://forgelabs.com/sla-design-guide/"
  },
  [EManufacturingProcess.FDM]: {
    text: "Most parts will be printed within ±0.25 mm or ±0.2%, whichever is greater.",
    url: "https://forgelabs.com/fdm-design-guide/"
  },
  [EManufacturingProcess.DMLS]: {
    text: "Parts will be printed within our standard tolerances: <100 mm: ±0.2 mm, <200 mm: ±0.3 mm, <300 mm: ±0.4 mm, 300–400 mm: ±0.5 mm",
    url: "https://forgelabs.com/dmls-design-guide/"
  },
  [EManufacturingProcess.DP]: {
    text: "",
    url: ""
  }
}
