import { Button, CustomBadge, Table } from "@/components"
import {
  BadgeColor,
  BadgeType,
  ButtonHierarchy,
  Size
} from "@/enums/common.enum"
import { ColumnType } from "antd/es/table"
import { Quotes } from "@/interfaces/table.interface"
import { quotesListColumn } from "@/constants/quoteListTable.constant"
import { EOrderStatus } from "@/enums/quotesList.enum"
import "./QuotesListTable.scss"

interface QuotesListTableProps {
  data: Quotes[]
  onDetail: (
    invoiceId: number,
    hash: string,
    status: string,
    id: number
  ) => void
}

export const BadgeColorQuoteStatus = {
  [EOrderStatus.WAITING_FOR_REVIEW]: BadgeColor.INPROGRESS,
  [EOrderStatus.FIRM_OFFER_SENT]: BadgeColor.SUCCESS,
  [EOrderStatus.CANCEL_BY_ADMIN]: BadgeColor.ERROR
}

const QuotesListTable = ({
  data,
  onDetail
}: QuotesListTableProps): JSX.Element => {
  const columns: ColumnType<Quotes>[] = [
    ...quotesListColumn,
    {
      title: <div className="status-col">STATUS</div>,
      key: "quoteStatus",
      width: "25%",
      render: (_: any, record: Quotes) => (
        <div className="status-quote-col">
          <CustomBadge
            content={record.statusDisplay}
            type={BadgeType.TONAL}
            size={Size.MEDIUM}
            color={
              BadgeColorQuoteStatus[
                record.status as keyof typeof BadgeColorQuoteStatus
              ]
            }
          />
        </div>
      )
    },
    {
      title: "",
      key: "action",
      width: "15%",
      render: (_: any, record: Quotes) => (
        <div style={{ textAlign: "center" }}>
          <Button
            customSize={Size.EXTRA_SMALL}
            hierarchy={ButtonHierarchy.TONAL}
            onClick={() =>
              onDetail(
                record?.invoice_id,
                record?.invoice_hash,
                record.status,
                record.id
              )
            }
          >
            Details
          </Button>
        </div>
      )
    }
  ]

  return <Table data={data} columns={columns} rowKey="id" />
}

export default QuotesListTable
