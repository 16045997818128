import React, { useEffect } from "react"
import "./SideBar.scss"
import { NavLink, NavLinkRenderProps, useLocation } from "react-router-dom"
import {
  avatarDefaultImage,
  Logo,
  Logout,
  ThreeHorizontalLines,
  UserAvatarIcon
} from "@/assets"
import { ITabs } from "@/interfaces/common.interface"
import { useDispatch, useSelector } from "react-redux"
import { LOGOUT_ACTION } from "@/store/action"
import { IUserStore } from "@/store/user"
import { RootState } from "@/store"
import { IAuthStore, setIsLogOut } from "@/store/auth"
import { TabType } from "@/enums/common.enum"
import { IQuoteStore, setIsSidebarOpen } from "@/store/quote"
interface ISideBarProps {
  tabs: ITabs[]
}
const SideBar: React.FC<ISideBarProps> = ({ tabs }) => {
  const location = useLocation()
  const isProfileRoute = location.pathname.includes("/profile")
  const dispatch = useDispatch()
  const isSidebarOpen = useSelector<RootState, IQuoteStore>(
    (s) => s.quote
  ).isSidebarOpen
  const { userInfo } = useSelector<RootState, IUserStore>((s) => s.user)
  const { isAuthenciated, isLogOut } = useSelector<RootState, IAuthStore>(
    (state) => state.auth
  )
  const onLogOut = () => {
    dispatch(setIsLogOut(true))
  }

  useEffect(() => {
    if (isLogOut) {
      dispatch({ type: LOGOUT_ACTION })
    }
  }, [isLogOut])

  const onChangeSidebarStatus = () => {
    dispatch(setIsSidebarOpen(!isSidebarOpen))
  }

  const returnSidebarStatus = () => {
    return isSidebarOpen ? "sidebar-open" : "sidebar-close"
  }
  const visibleTabs = tabs.filter(
    (tab) => tab.type === TabType.CONTACT_US || isAuthenciated
  )

  return (
    <div className={`sidebar-wrapper ${returnSidebarStatus()}`}>
      <div className="sidebar-container">
        <div className="sidebar-top">
          <img
            onClick={() => window.location.replace("/")}
            className={`sidebar-logo ${returnSidebarStatus()}`}
            src={Logo}
            alt="img"
          />

          <div className="sidebar-items">
            <div className="sidebar-item">
              <img
                className="sidebar-item-toggle-sidebar"
                src={ThreeHorizontalLines}
                alt="img"
                width={24}
                height={24}
                onClick={() => onChangeSidebarStatus()}
              />
            </div>

            {visibleTabs.map((tab, index) => (
              <NavLink
                className={({ isActive }) => {
                  return isActive || location.pathname.includes(tab.routerLink)
                    ? `sidebar-item-active sidebar-item-container`
                    : "sidebar-item-container"
                }}
                to={tab.routerLink}
                key={index}
                end
              >
                {({ isActive }) => (
                  <div className="sidebar-item">
                    <img
                      src={
                        isActive || location.pathname.includes(tab.routerLink)
                          ? tab.imgSrcActive
                          : tab.imgSrc
                      }
                      alt="img"
                      width={24}
                      height={24}
                    />
                    <p className={`sidebar-item-text ${returnSidebarStatus()}`}>
                      {tab.name}
                    </p>
                  </div>
                )}
              </NavLink>
            ))}
          </div>
        </div>

        {isAuthenciated && (
          <div className="sidebar-bottom">
            <NavLink
              className={`${
                isProfileRoute
                  ? `sidebar-bottom-avatar-active`
                  : "sidebar-bottom-avatar"
              }`}
              to={"/profile/infor"}
              end
            >
              {({ isActive }) => (
                <div className="sidebar-item">
                  <img
                    src={
                      userInfo?.avatar || isActive
                        ? UserAvatarIcon
                        : UserAvatarIcon
                    }
                    alt=""
                    width={24}
                    height={24}
                  />
                  <p className={`sidebar-item-text ${returnSidebarStatus()}`}>
                    Account
                  </p>
                </div>
              )}
            </NavLink>

            <div className="sidebar-item" onClick={onLogOut}>
              <img src={Logout} alt="" />

              <p className={`sidebar-item-text ${returnSidebarStatus()}`}>
                Log out
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default SideBar
