import React from "react"
import { IToast } from "@/interfaces/common.interface"
import { Row, Col } from "antd"
import { RoundCloseIcon } from "@/assets"
import "./Toast.scss"

const Toast: React.FC<IToast> = ({
  closeToast,
  title,
  content,
  statusIcon
}) => {
  return (
    <div className="toast-container">
      <Col flex="none" style={{ height: 20 }}>
        <img
          src={statusIcon}
          width={16}
          height={16}
          alt="status"
          style={{ verticalAlign: "center", padding: "2px" }}
        />
      </Col>
      <Col flex="auto" style={{ padding: "0px 8px" }}>
        <Row
          justify="space-between"
          style={{ flexWrap: "nowrap" }}
          align="middle"
          gutter={4}
        >
          <div>
            <p className="title-toast">{title}</p>
            <p className="content-text">{content}</p>
          </div>
          <Col flex="none" style={{ height: 20, width: 20 }}>
            <img
              src={RoundCloseIcon}
              onClick={closeToast}
              width={12}
              height={12}
              alt="close"
              style={{ padding: "4px" }}
            />
          </Col>
        </Row>
      </Col>
    </div>
  )
}

export default Toast
