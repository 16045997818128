import { Flex } from "antd"
import * as React from "react"
import InfoForm from "../components/InfoForm/InfoForm"
import PasswordForm from "../components/PasswordForm/PasswordForm"
import "./ProfilePage.scss"

export default function ProfilePage(): JSX.Element {
  const [isEdit, setIsEdit] = React.useState<boolean>(false)
  const [isPasswordChange, setIsPasswordChange] = React.useState<boolean>(false)

  return (
    <Flex vertical gap={24} style={{ paddingRight: "24px" }}>
      <div className="info-form-box">
        <p className="text-title">
          {!isEdit ? "Information" : "Personal Information"}
        </p>
        <InfoForm isEdit={isEdit} setEdit={setIsEdit}></InfoForm>
      </div>
      <div className="password-form-box">
        <p className="text-title">
          {!isPasswordChange ? "Password" : "Change password"}
        </p>
        <PasswordForm
          isPasswordChange={isPasswordChange}
          setChangePassword={setIsPasswordChange}
        ></PasswordForm>
      </div>
    </Flex>
  )
}
