import { priceDisplay } from "@/utils/functionHelper"
import "./OrderDetailItem.scss"
import { LineItem } from "@/services/apiDigifabster/quote"
import { CustomBadge } from "@/components"
import { BadgeColor, BadgeType, Size } from "@/enums/common.enum"
import { DrawingIcon } from "@/assets"
import { useSelector } from "react-redux"
import { IQuoteStore } from "@/store/quote"
import { RootState } from "@/store"
import { IUserStore } from "@/store/user"
import { EOrderStatus } from "@/enums/quotesList.enum"
import {
  checkPlaceholderFile,
  getFileNameWithoutExtension
} from "@/utils/stringHelper"

interface IQuoteItemProps {
  item: LineItem
  currency: string
  deliveryDays?: number
}

const statusDisplay: Partial<Record<EOrderStatus, string>> = {
  [EOrderStatus.ON_HOLD]: "On Hold",
  [EOrderStatus.MANUFACTURING]: "In Production",
  [EOrderStatus.PLACED]: "New Order",
  [EOrderStatus.WAITING_FOR_REVIEW]: "Waiting for review",
  [EOrderStatus.FIRM_OFFER_SENT]: "Quote Sent",
  [EOrderStatus.PO_PROVIDED]: "PO Provided",
  [EOrderStatus.FREE_FOR_MANUFACTURING]: "Free for manufacturing",
  [EOrderStatus.PLANNING_CONFIRMED]: "Planning Confirmed",
  [EOrderStatus.POST_PRODUCTION]: "Post Production",
  [EOrderStatus.RE_MANUFACTURING]: "Re-printing",
  [EOrderStatus.READY_FOR_SHIPPING]: "Ready for shipping",
  [EOrderStatus.SHIPPED]: "Shipped",
  [EOrderStatus.READY_FOR_PICKUP]: "Ready for pickup",
  [EOrderStatus.DELIVERED]: "Order Complete",
  [EOrderStatus.PO_PAYMNET_DUE]: "PO Payment due",
  [EOrderStatus.CANCEL_BY_ADMIN]: "Cancelled by admin",
  [EOrderStatus.CANCEL_BY_USER]: "Cancelled by user"
}

function OrderDetailItem({ item, currency, deliveryDays }: IQuoteItemProps) {
  const { product, config, drawing_files, self_notes, status } = item
  const { userInfoNew } = useSelector<RootState, IUserStore>((s) => s.user)
  const country = userInfoNew?.country || ""

  const getColorStatusOfPart = (status: string) => {
    switch (status) {
      case EOrderStatus.ON_HOLD:
      case EOrderStatus.CANCEL_BY_ADMIN:
      case EOrderStatus.CANCEL_BY_USER:
        return BadgeColor.ERROR

      case EOrderStatus.WAITING_FOR_REVIEW:
      case EOrderStatus.MANUFACTURING:
        return BadgeColor.INPROGRESS

      default:
        return BadgeColor.ROLE
    }
  }
  return (
    <div className="order-detail-item-box">
      <div className="order-detail-item-box-col-1">
        <div>
          <img src={product.thumb_120x120} alt="" width={68} height={54} />
        </div>
        <div className="">
          <p className="order-detail-item-box-col-1-title">
            {checkPlaceholderFile(self_notes || "") &&
            status === EOrderStatus.WAITING_FOR_REVIEW
              ? getFileNameWithoutExtension(product?.title)
              : product?.title}
          </p>
          <div className="order-detail-item-box-col-1-size">
            <p>{`${
              product?.units === "mm"
                ? Math.round(product?.size?.x || 0)
                : product?.size?.x?.toFixed(2) || 0
            } x 
            ${
              product?.units === "mm"
                ? Math.round(product?.size?.y || 0)
                : product?.size?.y?.toFixed(2) || 0
            } x 
            ${
              product?.units === "mm"
                ? Math.round(product?.size?.z || 0)
                : product?.size?.z?.toFixed(2) || 0
            } 
            ${product?.units || ""}`}</p>
          </div>

          {!!drawing_files.length && (
            <div className="order-detail-drawings">
              {drawing_files?.map((item: any) => (
                <div key={item.id} className="order-detail-drawings-item">
                  <img width={12} height={12} src={DrawingIcon} alt="" />
                  <p>{item.name}</p>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      <div className="order-detail-item-box-col-2">
        <p>{item.technology_title}</p>
        <p>
          {item.material_title} ,{item?.config?.color?.name_for_user}
        </p>
        {config?.layer_thickness?.name_for_user && (
          <p>{config?.layer_thickness?.name_for_user || ""}</p>
        )}
        <p>
          {config?.filling?.name_for_user ||
            config?.filling?.value?.toFixed(1) + " percent" ||
            ""}
        </p>
      </div>
      <div className="order-detail-item-box-col-5">
        <p>Visual Inspection</p>
        {config?.post_production?.length > 0
          ? config.post_production.map((item) => (
              <p key={item.title}>
                {item.title.replace(new RegExp(` - ?${country}$`), "")}{" "}
                {item.countable && `(${item.quantity})`}
              </p>
            ))
          : ""}{" "}
      </div>
      <div className="order-detail-item-box-col-3">
        <p>{item.amount}</p>
      </div>
      <div className="order-detail-item-box-col-4">
        <div className="order-detail-item-box-col-4-price">
          <p>
            {currency}
            {priceDisplay(item.total_price)}
          </p>
          <p>
            {currency}
            {priceDisplay(item.unit_price)} / part
          </p>
        </div>

        {item.status && (
          <CustomBadge
            customClassName="order-detail-item-box-col-4-badge"
            content={statusDisplay[item?.status as EOrderStatus] || ""}
            color={getColorStatusOfPart(item.status)}
            type={BadgeType.TONAL}
            size={Size.SMALL}
          />
        )}
      </div>
    </div>
  )
}

export default OrderDetailItem
