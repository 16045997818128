import { format } from "date-fns"

export const quotesListColumn = [
  {
    title: "#",
    dataIndex: "no",
    key: "no",
    width: "10%"
  },
  {
    title: "ORDER DATE",
    dataIndex: "orderDate",
    key: "orderDate",
    render: (date: string) => format(date, "MM/dd/yyyy"),
    width: "25%"
  },
  {
    title: "TOTAL",
    dataIndex: "total",
    key: "total",
    width: "25%"
  }
]
