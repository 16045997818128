import React from "react"
import "./TopToolbar.scss"
import { DeleteActiveIcon, SimpleEditIcon } from "@/assets"
import { Button } from "@/components"
import { ButtonHierarchy, Size, Type } from "@/enums/common.enum"

type TopToolbarProps = {
  isEditable: boolean
  isDeletable: boolean
  onSubmitManualRequest: () => void
  openDrawer: () => void
  onDelete: () => void
  isMultiple: boolean
  numberPart: number
}

const TopToolbar = ({
  isEditable,
  isDeletable,
  onSubmitManualRequest,
  openDrawer,
  onDelete,
  isMultiple,
  numberPart
}: TopToolbarProps) => {
  return (
    <div className="top-toolbar">
      <div className="top-toolbar-container">
        <div className="top-toolbar-actions">
          <Button
            customClassName="top-toolbar-button-edit"
            customType={Type.NEUTRAL}
            hierarchy={ButtonHierarchy.OUTLINE}
            disabled={!isEditable}
            icon={<img src={SimpleEditIcon} alt="Edit Icon" />}
            onClick={openDrawer}
          >
            <p className="top-toolbar-button-text">
              {isMultiple ? `Configure (${numberPart} parts)` : "Edit"}
            </p>
          </Button>
          <Button
            customClassName="top-toolbar-button-delete"
            hierarchy={ButtonHierarchy.OUTLINE}
            disabled={!isDeletable}
            icon={<img src={DeleteActiveIcon} alt="Delete Icon" />}
            onClick={onDelete}
          >
            <p className="top-toolbar-button-text">Delete</p>
          </Button>
        </div>

        <div className="top-toolbar-help">
          <p className="top-toolbar-help-text">
            Have Questions?{" "}
            <span
              onClick={onSubmitManualRequest}
              className="top-toolbar-help-link"
            >
              Ask for help
            </span>
          </p>
        </div>
      </div>
    </div>
  )
}

export default TopToolbar
