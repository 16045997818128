import {
  Button,
  CountrySelect,
  FormField,
  SelectDropdown,
  TextField
} from "@/components"
import {
  Controller,
  FieldError,
  FormState,
  SubmitHandler,
  useFormContext,
  UseFormHandleSubmit
} from "react-hook-form"
import {
  IBillingFormValues,
  IFormValuesBillingAddress,
  IFormValuesBillingContact
} from "@/interfaces/checkout.interface"
import { Col, ConfigProvider, Form, Row } from "antd"
import { ButtonHierarchy, Size, Type } from "@/enums/common.enum"
import {
  DEFAULT_CANADA,
  DEFAULT_COUNTRY_ID,
  DEFAULT_US,
  EMTY_STATE,
  errorStatus
} from "@/constants/common.constant"
import { useEffect, useState } from "react"
import { CountryOptions, IState } from "@/interfaces/common.interface"
import { getStatesList } from "@/constants/countries"
import "./BillingInfoEditForm.scss"
import { copyIcon } from "@/assets"
import { useSelector } from "react-redux"
import { RootState } from "@/store"
import { IUserStore } from "@/store/user"
import { ICheckoutForm } from "@/interfaces/checkout.interface"
import { ILastAddress } from "@/store/user/type"

interface BillingInfoEditFormProps {
  disabled: boolean
  submitText: string
  onSubmit: SubmitHandler<IBillingFormValues>
  prefillData?: ICheckoutForm
  handlePreStep: () => void
  last_billing_address: ILastAddress
}

function BillingInfoEditForm({
  submitText,
  disabled,
  onSubmit,
  prefillData,
  handlePreStep,
  last_billing_address
}: BillingInfoEditFormProps) {
  const [countriesList, setCountriesList] = useState<CountryOptions[]>([])
  const [stateList, setStateList] = useState<CountryOptions[]>([])
  const { userInfoNew, company: billingCompany } = useSelector<
    RootState,
    IUserStore
  >((s) => s.user)
  const country = userInfoNew?.country
  const company_billing_address = billingCompany.billing_address
  const DEFAULT_COUNTRY = country === "US" ? DEFAULT_US : DEFAULT_CANADA
  const { control, handleSubmit, formState, watch, reset, setValue, setError } =
    useFormContext<IBillingFormValues>()
  const { errors, isDirty, isSubmitted } = formState
  const acceptCountries = ["CA", "US"]

  useEffect(() => {
    if (!isDirty) {
      const prefillCountry = countriesList.find(
        (item) =>
          item.iso2 === company_billing_address?.country ||
          last_billing_address?.country
      )
      const mapprefillCountry = {
        name: prefillCountry?.title || DEFAULT_COUNTRY.name,
        id: prefillCountry?.id || DEFAULT_COUNTRY.id,
        iso2: prefillCountry?.iso2 || DEFAULT_COUNTRY.iso2
      }
      setValue(
        "country",
        prefillData?.billing?.country ||
          mapprefillCountry || {
            id: DEFAULT_COUNTRY.id,
            name: DEFAULT_COUNTRY.name,
            iso2: DEFAULT_COUNTRY.iso2
          }
      )
    }
  }, [countriesList, isDirty])

  useEffect(() => {
    const getStates = async (countryId: number) => {
      const states = await getStatesList(countryId)
      setStateList(states)
      if (!isDirty) {
        const statePrefilled =
          company_billing_address?.state || last_billing_address?.state
        const prefillState = states.find((item) => item.iso2 === statePrefilled)
        const mapprefillState = {
          name: prefillState?.title || "",
          id: prefillState?.id || "",
          iso2: prefillState?.iso2 || ""
        }
        setValue(
          "state",
          prefillData?.billing?.state || mapprefillState || EMTY_STATE
        )
      }
    }

    const countryId = watch("country").id
    if (countryId) {
      getStates(Number(countryId))
      return
    }
    setStateList([])
  }, [watch("country")])

  const handleChangeState = (
    stateId: string,
    onChangeCallback: (...event: any[]) => void
  ) => {
    const state = stateList.find((country) => country.id === stateId)
    const data = { iso2: state?.iso2, name: state?.title, id: state?.id }
    onChangeCallback(data || "")
  }

  const handleChangeCountry = (
    countryId: string,
    onChangeCallback: (...event: any[]) => void
  ) => {
    const state = countriesList.find((country) => country.id === countryId)
    const data = { iso2: state?.iso2, name: state?.title, id: state?.id }
    setValue("state", {} as IState)
    isSubmitted &&
      setError("state", { type: "required", message: "State is required" })
    onChangeCallback(data || {})
  }

  const copyBillingContactData = () => {
    const existingData: IFormValuesBillingContact = {
      firstName: prefillData?.delivery_address?.name || "",
      lastName: prefillData?.delivery_address?.surname || "",
      phoneNumber: prefillData?.delivery_address?.phoneNumber || "",
      accountPayEmail: prefillData?.delivery_address?.email || ""
    }

    Object.keys(existingData).forEach((key) => {
      setValue(
        key as keyof IFormValuesBillingContact,
        existingData[key as keyof IFormValuesBillingContact],
        { shouldDirty: true, shouldValidate: isSubmitted }
      )
    })
  }

  const copyBillingAdressData = () => {
    const existingData: IFormValuesBillingAddress = {
      company: prefillData?.delivery_address?.company || "",
      state: prefillData?.delivery_address?.state || EMTY_STATE,
      country: prefillData?.delivery_address?.country || {
        id: DEFAULT_COUNTRY_ID,
        name: "Canada",
        iso2: "CA"
      },
      addressLine1: prefillData?.delivery_address?.addressLine1 || "",
      addressLine2: prefillData?.delivery_address?.addressLine2 || "",
      city:
        prefillData?.delivery_address?.city || last_billing_address?.city || "",
      zipCode: prefillData?.delivery_address?.zipCode || ""
    }

    Object.keys(existingData).forEach((key) => {
      setValue(
        key as keyof IFormValuesBillingAddress,
        existingData[key as keyof IFormValuesBillingAddress],
        { shouldDirty: true, shouldValidate: isSubmitted }
      )
    })
  }
  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: '"Open Sans", sans-serif'
        }
      }}
    >
      <Form onFinish={handleSubmit(onSubmit)}>
        <div className="billing-contact">
          <p className="billing-contact-title">Billing Contact</p>
          <div style={{ paddingBottom: "8px" }}>
            <Button
              customSize={Size.SMALL}
              hierarchy={ButtonHierarchy.TONAL}
              customType={Type.NEUTRAL}
              onClick={copyBillingContactData}
            >
              <img src={copyIcon} alt="" /> Copy from contact
            </Button>
          </div>
          <Row gutter={12} className="pb-12">
            <Col span={12}>
              <FormField
                textLabel="First name *"
                errorText={errors?.firstName?.message}
              >
                <Controller
                  name="firstName"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder="Enter first name"
                      status={errorStatus(errors?.firstName)}
                    />
                  )}
                />
              </FormField>
            </Col>
            <Col span={12}>
              <FormField
                textLabel="Last name *"
                errorText={errors.lastName?.message}
              >
                <Controller
                  name="lastName"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder="Enter last name"
                      status={errorStatus(errors.lastName)}
                    />
                  )}
                />
              </FormField>
            </Col>
          </Row>
          <Row gutter={12} className="pb-12">
            <Col span={12}>
              <FormField
                textLabel="Phone Number *"
                errorText={errors.phoneNumber?.message}
              >
                <Controller
                  name="phoneNumber"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="tel"
                      placeholder="Enter phone number"
                      status={errorStatus(errors.phoneNumber)}
                    />
                  )}
                />
              </FormField>
            </Col>
            <Col span={12}>
              <FormField
                textLabel="Accounts Payable Email *"
                errorText={errors.accountPayEmail?.message}
              >
                <Controller
                  name="accountPayEmail"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder="Enter Email"
                      status={errorStatus(errors.accountPayEmail)}
                    />
                  )}
                />
              </FormField>
            </Col>
          </Row>
        </div>
        <div className="billing-address">
          <p className="billing-address-title">Billing Address</p>
          <div style={{ paddingBottom: "8px" }}>
            <Button
              customSize={Size.SMALL}
              hierarchy={ButtonHierarchy.TONAL}
              customType={Type.NEUTRAL}
              onClick={copyBillingAdressData}
            >
              <img src={copyIcon} alt="" /> Copy from delivery address
            </Button>
          </div>
          {/* <p className="billing-address-notice">
            You don’t have a Billing Address. Please add one to proceed with the
            order
          </p> */}
          <Row gutter={12} className="pb-12">
            <Col span={12}>
              <FormField
                textLabel="Company *"
                errorText={errors.company?.message}
              >
                <Controller
                  name="company"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder="Enter company name"
                      status={errorStatus(errors?.company)}
                    ></TextField>
                  )}
                ></Controller>
              </FormField>
            </Col>
            <Col span={12}>
              <FormField textLabel="Tax Number">
                <Controller
                  name="taxNumber"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder="Enter tax number"
                      type="number"
                    ></TextField>
                  )}
                ></Controller>
              </FormField>
            </Col>
          </Row>
          <Row gutter={12} className="pb-12">
            <Col span={12}>
              <FormField
                textLabel="Address Line 1 *"
                errorText={errors.addressLine1?.message}
              >
                <Controller
                  name="addressLine1"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      status={errorStatus(errors.addressLine1)}
                      {...field}
                      placeholder="Address Line 1"
                    ></TextField>
                  )}
                ></Controller>
              </FormField>
            </Col>
            <Col span={12}>
              <FormField
                textLabel="Address Line 2"
                errorText={errors.addressLine2?.message}
              >
                <Controller
                  name="addressLine2"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      status={errorStatus(errors.addressLine2)}
                      {...field}
                      placeholder="Address Line 2"
                    ></TextField>
                  )}
                ></Controller>
              </FormField>
            </Col>
          </Row>
          <Row gutter={12} className="pb-12">
            <Col span={12}>
              <FormField textLabel="City *" errorText={errors.city?.message}>
                <Controller
                  name="city"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      status={errorStatus(errors.city)}
                      placeholder="City"
                      {...field}
                    ></TextField>
                  )}
                />
              </FormField>
            </Col>
            <Col span={12}>
              <FormField
                textLabel="State / Province *"
                errorText={errors.state?.message}
              >
                <Controller
                  name="state"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <SelectDropdown
                      status={errorStatus(errors?.state as FieldError)}
                      width={"100%"}
                      dropdownClassName="dropdown-menu"
                      value={value?.id}
                      searchBar={true}
                      valueChangeToClear={watch("country")?.id}
                      onChange={(stateId) =>
                        handleChangeState(stateId, onChange)
                      }
                      options={stateList}
                      virtual={false}
                      placeholder="Select State / Province"
                    />
                  )}
                />
              </FormField>
            </Col>
          </Row>
          <Row gutter={12} className="pb-8">
            <Col span={12}>
              <FormField
                textLabel={country === "US" ? "Zip Code *" : "Postal Code *"}
                errorText={errors.zipCode?.message}
              >
                <Controller
                  name="zipCode"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      status={errorStatus(errors.zipCode)}
                      placeholder={
                        country === "US"
                          ? "Enter zip code"
                          : "Enter postal code"
                      }
                    ></TextField>
                  )}
                ></Controller>
              </FormField>
            </Col>
            <Col span={12}>
              <FormField
                textLabel="Country *"
                errorText={errors.country?.message}
              >
                <Controller
                  name="country"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <CountrySelect
                      countriesList={countriesList}
                      status={errorStatus(errors?.country as FieldError)}
                      setCountriesList={setCountriesList}
                      flag={`https://flagcdn.com/w20/${value?.iso2?.toLowerCase()}.png`}
                      value={value.id}
                      width={"100%"}
                      dropdownClassName="dropdown-menu"
                      handleChange={(countryId) =>
                        handleChangeCountry(countryId, onChange)
                      }
                      acceptCountries={acceptCountries}
                      placeholder="Select your country"
                    />
                  )}
                />
              </FormField>
            </Col>
          </Row>
        </div>
        <div className="collapse-footer">
          <Button
            customType={Type.NEUTRAL}
            hierarchy={ButtonHierarchy.OUTLINE}
            onClick={handlePreStep}
          >
            Previous Step
          </Button>
          <Button htmlType="submit" disabled={disabled}>
            {submitText}
          </Button>
        </div>{" "}
      </Form>
    </ConfigProvider>
  )
}

export default BillingInfoEditForm
