export enum TabType {
  NEW_QUOTE = "NEW_QUOTE",
  QUOTES = "QUOTES",
  ORDERS = "ORDERS",
  CONTACT_US = "CONTACT_US",
  SPECIFICATION = "SPECIFICATION"
}

export enum Type {
  PRIMARY = "primary",
  NEUTRAL = "neutral",
  DEFAULT = "default"
}

export enum Size {
  EXTRA_SMALL = "extrasmall",
  SMALL = "small",
  MEDIUM = "medium",
  LARGE = "large",
  EXTRA_LARGE = "extralarge"
}

export enum ButtonHierarchy {
  OUTLINE = "outline",
  FILLED = "filled",
  LINK = "link",
  TONAL = "tonal"
}

export enum RadioButtonSize {
  RADIO_SIZE_SMALL = 16,
  RADIO_SIZE_MEDIUM = 20,
  DOT_SIZE_SMALL = 6,
  DOT_SIZE_MEDIUM = 8
}

export enum BadgeType {
  TONAL = "tonal",
  OUTLINE = "outline",
  SOLID = "solid"
}

export enum BadgeColor {
  BRAND = "brand",
  ROLE = "role",
  ERROR = "error",
  WARNING = "warning",
  SUCCESS = "success",
  INPROGRESS = "inprogress"
}
