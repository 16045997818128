import { IAvailableTechnology } from "@/store/product"

import { OrderDetailResponse } from "@/services/apiDigifabster/quote"

type ItemWithId = { id: number | string }

// compare two arrays of objects with id property and return how many matches are there
export const evaluateMatches = <T extends ItemWithId>(
  base_array?: T[],
  selected_array?: T[]
): {
  allMatch: boolean
  moreThanOne: boolean
  oneMatch: boolean
  editable: boolean
  deletable: boolean
} => {
  if (!base_array?.length || !selected_array?.length) {
    return {
      allMatch: false,
      moreThanOne: false,
      oneMatch: false,
      editable: false,
      deletable: false
    }
  }

  const matchCount = base_array.filter((item1) =>
    selected_array.some((item2) => item1.id === item2.id)
  ).length

  return {
    allMatch:
      matchCount === base_array.length && matchCount === selected_array.length,
    moreThanOne: matchCount > 1 && matchCount < base_array.length,
    oneMatch: matchCount === 1,
    editable: matchCount >= 1,
    deletable: matchCount >= 1
  }
}

// get the biggest product object depend on x size
export const getBiggestXObject = (quoteDetail?: OrderDetailResponse) => {
  if (!quoteDetail?.line_items || quoteDetail?.line_items?.length === 0) return

  const biggestObj = quoteDetail?.line_items.reduce((maxItem, currentItem) => {
    const currentX =
      currentItem?.product?.dfm_features?.part_sizes?.x || -Infinity
    const maxX = maxItem?.product?.dfm_features?.part_sizes?.x || -Infinity

    return currentX > maxX ? currentItem : maxItem
  }, quoteDetail?.line_items[0])

  const inchesValue = 0.0393701

  return {
    units: "inches", // convert length, width, height to inches
    length: biggestObj?.product?.dfm_features?.part_sizes?.x * inchesValue || 0,
    width: biggestObj?.product?.dfm_features?.part_sizes?.y * inchesValue || 0,
    height: biggestObj?.product?.dfm_features?.part_sizes?.z * inchesValue || 0
  }
}

//intersect the materials of selected items
export const intersectionMaterials = (
  suitableMaterial: Record<number, IAvailableTechnology[]>,
  selectedItems: number[]
): IAvailableTechnology[] => {
  if (selectedItems.length === 0) return []

  const materialMap: Record<number, Set<number>> = {}

  selectedItems?.forEach((itemId, index) => {
    suitableMaterial[itemId]?.forEach((material) => {
      if (index === 0) {
        materialMap[material.id] = new Set<number>(material.materials)
      } else {
        const intersection = new Set<number>()
        material.materials?.forEach((mat) => {
          if (materialMap[material.id]?.has(mat)) {
            intersection.add(mat)
          }
        })
        materialMap[material.id] = intersection
      }
    })
  })

  return Object.keys(materialMap).map((id) => ({
    id: parseInt(id),
    materials: Array.from(materialMap[parseInt(id)])
  }))
}
