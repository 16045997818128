import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useAppLoading } from "@/hooks/useLoading"

import { useGetPaymentLinkMutation } from "@/services/apiDigifabster/order"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import stripeConfig from "@/config/stripe"
import ManualReviewSection from "./components/ManualReviewSection/ManualReviewSection"
import CheckoutPageContent from "./components/ChekoutPageContent/CheckoutPageContent"

export default function CheckoutPage(): JSX.Element {
  const stripePromise = loadStripe(stripeConfig.apiKey || "")
  const [getPaymentLink, { isLoading }] = useGetPaymentLinkMutation()
  const { quoteId } = useParams()

  const [options, setOptions] = useState({ clientSecret: "" })

  useAppLoading([isLoading])
  useEffect(() => {
    onGetPaymentLink()
  }, [])

  const onGetPaymentLink = async () => {
    const { data, error } = await getPaymentLink({
      orderId: Number(quoteId)
    })
    if (!data || error) return setOptions({ clientSecret: "error" })
    setOptions({ clientSecret: data?.clientSecret })
  }
  return (
    <>
      {options?.clientSecret === "error" ? (
        <ManualReviewSection></ManualReviewSection>
      ) : (
        <>
          {options?.clientSecret ? (
            <Elements stripe={stripePromise} options={options}>
              <CheckoutPageContent clientSecret={options?.clientSecret} />
            </Elements>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  )
}
