export const retryOnDigifabsterTimeout = async <T>(
  fn: () => Promise<T>,
  maxRetries: number = Infinity,
  delay: number = 1000
): Promise<T | undefined> => {
  let attempts = 0
  while (true) {
    try {
      return await fn()
    } catch (error: any) {
      attempts++
      if (error.status === 500 || error.status === 401) {
        if (attempts >= maxRetries) {
          return
        }
        await new Promise((resolve) => setTimeout(resolve, delay))
      } else return
    }
  }
}
