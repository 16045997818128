import { useGetSuitableMaterialsMutation } from "@/services/apiDigifabster/model"
import { RootState } from "@/store"
import { IProductStore } from "@/store/product"
import { IQuoteStore } from "@/store/quote"
import { chunkArray } from "@/utils/functionHelper"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useCheckWallThickness } from "./useCheckWallThickness"
import { Product } from "@/services/apiDigifabster/quote"

export const useSuitableMaterials = () => {
  const { technologies, suitableMaterial } = useSelector<
    RootState,
    IProductStore
  >((s) => s.product)
  const { currentQuote, quoteDetail } = useSelector<RootState, IQuoteStore>(
    (s) => s.quote
  )
  const [isProcessingId, setIsProcessingId] = useState<number[]>([])

  const [getSuitableMaterials, { isLoading }] =
    useGetSuitableMaterialsMutation()
  const { handleCheckWallThickness } = useCheckWallThickness()

  useEffect(() => {
    if (!technologies.length || !currentQuote || !currentQuote.products) return

    const modelIds = currentQuote.products
      .map((e) => e.parent_model_id || e.model_id)
      .filter((e) => !suitableMaterial[e])
    const chuckedModelIds = chunkArray(modelIds, 10)
    handleGetWallThickness(currentQuote.products, chuckedModelIds)
  }, [currentQuote, technologies])

  const handleGetWallThickness = async (
    product: Product[],
    chuckedModelIds: any[][]
  ) => {
    await handlGetSuitableMaterials(chuckedModelIds)
    await handleCheckWallThickness(product)
  }

  const flattenData = (dataArray: any[]) => {
    const flattenedData: Record<string, any>[] = []

    dataArray.forEach((item) => {
      const data = item.data
      if (data && typeof data === "object") {
        Object.keys(data).forEach((modelId) => {
          flattenedData.push({ [modelId]: data[modelId] })
        })
      }
    })

    return flattenedData
  }

  const handlGetSuitableMaterials = async (chuckedModelIds: any[][]) => {
    setIsProcessingId(chuckedModelIds?.[0])
    const rs = await Promise.all(
      chuckedModelIds.map((e) => getSuitableMaterials(e))
    )
    const rs2 = flattenData(rs.flat())
    const hasNotProcessed = rs2.some((item) => {
      const modelId = Object.keys(item)[0]
      return item[modelId].is_ready === false
    })

    if (hasNotProcessed) {
      const notProcessedMaterial = rs2.filter((item) => {
        const modelId = Object.keys(item)[0]

        return item[modelId].is_ready === false
      })

      const productNotProcessed = chuckedModelIds?.[0].filter((item) => {
        return notProcessedMaterial.some((e) => e[item])
      })
      setTimeout(async () => {
        await handlGetSuitableMaterials(chunkArray(productNotProcessed, 10))
      }, 5000)
    } else {
      setIsProcessingId([])
    }
  }

  return { isLoading, isProcessingId }
}
