import "./ViewerModal.scss"
import { Modal, Model, Loading } from "@/components"
import {
  ModelResponse,
  useResizeModelMutation
} from "@/services/apiDigifabster/model"
import DFMCheck from "../DFMCheck/DFMCheck"
import { ITechMap } from "@/interfaces/order.interface"
import {
  EModelAnalysisStatus,
  IModelAnalysis,
  IProductStore
} from "@/store/product"
import { useEffect, useMemo, useState } from "react"
import {
  BlockIcon,
  OrderReviewIcon,
  SuccessDFMIcon,
  SuccessToastIcon,
  WarningDFMIcon
} from "@/assets"
import { Config, ProductOrderDetail } from "@/services/apiDigifabster/quote"
import { EUnit } from "@/enums/unit.enum"
import { useAppLoading } from "@/hooks/useLoading"
import { useSelector } from "react-redux"
import { RootState } from "@/store"
import { IQuoteStore } from "@/store/quote"
import * as toast from "@/utils/Toast"
import PopOverSelect from "../PopOverSelect/PopOverSelect"
import { EManufacturingProcess } from "@/enums/editSpecifications.enum"
import { IUserStore } from "@/store/user"

const techMap: {
  [key in EManufacturingProcess]: ITechMap
} = {
  [EManufacturingProcess.SLS]: {
    thickness: "0.8",
    partSize: "340 x 340 x 600 mm",
    trappedVolume: "",
    holeSize: "1.5",
    minimumDetailSize: "0.5",
    warpingAndShrinkage: "Long, flat and thin features may deform.",
    complexity: ""
  },
  [EManufacturingProcess.MJF]: {
    thickness: "0.8",
    partSize: "380 x 284 x 380 mm",
    trappedVolume: "",
    holeSize: "1.5",
    minimumDetailSize: "0.5",
    warpingAndShrinkage: "Long, flat and thin features may deform.",
    complexity: ""
  },
  [EManufacturingProcess.SLA]: {
    thickness: "0.8",
    partSize: "1500 x 750 x 550 mm",
    trappedVolume: "",
    holeSize: "0.5",
    minimumDetailSize: "0.5",
    warpingAndShrinkage: "",
    complexity: ""
  },
  [EManufacturingProcess.FDM]: {
    thickness: "1",
    partSize: "914 x 610 x 914 mm",
    trappedVolume: "",
    holeSize: "1",
    minimumDetailSize: "1",
    warpingAndShrinkage: "",
    complexity: "Organic shapes and intricate details may not resolve."
  },
  [EManufacturingProcess.DMLS]: {
    thickness: "0.8",
    partSize: "400 x 300 x 400 mm",
    trappedVolume: "",
    holeSize: "1.5",
    minimumDetailSize: "0.5",
    warpingAndShrinkage: "Long, flat and thin features may deform.",
    complexity: ""
  },
  [EManufacturingProcess.DP]: {
    thickness: "",
    partSize: "",
    trappedVolume: "",
    holeSize: "",
    minimumDetailSize: "",
    warpingAndShrinkage: "",
    complexity: ""
  }
}

export const tagStatusClass = {
  [EModelAnalysisStatus.LOADING]: {
    text: "Checking for Manufacturing Issues....",
    icon: OrderReviewIcon,
    className: "viewer-modal-analysis-heading-title-loading"
  },
  [EModelAnalysisStatus.SUCCESS]: {
    text: "Ready To Order",
    icon: SuccessDFMIcon,
    className: ""
  },
  [EModelAnalysisStatus.WARNING]: {
    text: "Manufacturing Issues Detected",
    icon: WarningDFMIcon,
    className: "viewer-modal-analysis-heading-title-warning"
  },
  [EModelAnalysisStatus.ERROR]: {
    text: "Part Contains Manufacturing Issues",
    icon: BlockIcon,
    className: "viewer-modal-analysis-heading-title-error"
  },
  [EModelAnalysisStatus.REVIEW]: {
    text: "Instant Price Unavailable",
    icon: "",
    className: "viewer-modal-analysis-heading-title-error"
  }
}

export const tagStatusCheckClass = {
  [EModelAnalysisStatus.LOADING]: {
    text: "Checking for Manufacturing Issues....",
    icon: OrderReviewIcon,
    className: "corner-bottom-right-bevel-loading"
  },
  [EModelAnalysisStatus.SUCCESS]: {
    text: "Ready To Order",
    icon: SuccessToastIcon,
    className: "corner-bottom-right-bevel-success"
  },
  [EModelAnalysisStatus.WARNING]: {
    text: "Manufacturing Issues Detected",
    icon: WarningDFMIcon,
    className: "corner-bottom-right-bevel-warning"
  },
  [EModelAnalysisStatus.ERROR]: {
    text: "Part Contains Manufacturing Issues",
    icon: BlockIcon,
    className: "corner-bottom-right-bevel-error"
  },
  [EModelAnalysisStatus.REVIEW]: {
    text: "Instant Price Unavailable",
    icon: "",
    className: "corner-bottom-right-bevel-error"
  }
}

interface ViewerModalProps {
  statusTag: EModelAnalysisStatus
  isModalOpen: boolean
  onCancel: Function
  currentPart?: ModelResponse
  loading?: boolean
  analysis?: IModelAnalysis
  technology: EManufacturingProcess
  product?: ProductOrderDetail
  material?: number
  config?: Config
  isProcessing?: boolean
}

function ViewerModal({
  statusTag,
  isModalOpen,
  onCancel,
  currentPart,
  loading,
  analysis,
  technology,
  product,
  material,
  config,
  isProcessing
}: ViewerModalProps) {
  const { title, size, volume, file_model_viewer_url, units } =
    currentPart || {}
  const { quoteDetail } = useSelector<RootState, IQuoteStore>((s) => s.quote)
  const [selectUnit, setSelectUnit] = useState<EUnit>(EUnit.MM)
  const { technologies } = useSelector<RootState, IProductStore>(
    (s) => s.product
  )
  const { userInfoNew } = useSelector<RootState, IUserStore>((s) => s.user)
  const country = userInfoNew?.country || ""

  const [resizeModel, { isLoading }] = useResizeModelMutation()

  useAppLoading([isLoading])

  const [dfmAnalysisData, setDfmAnalysisData] = useState<ITechMap>(
    {} as ITechMap
  )

  useEffect(() => {
    setDfmAnalysisData(techMap[technology as keyof typeof techMap])

    setSelectUnit(EUnit[units?.toUpperCase() as keyof typeof EUnit])
  }, [analysis])

  const handleSelectedUnit = async (unit: EUnit) => {
    try {
      await resizeModel({
        orderId: quoteDetail?.id ?? 0,
        modelId: currentPart?.parent_model || currentPart?.id,
        key: product?.id,
        arg: {
          payload: {
            units: unit,
            config: {
              material_id: material ?? 0,
              config: {
                filling: config?.filling?.uuid || "",
                layer_thickness: config?.layer_thickness?.uuid || "",
                lead_time: config?.lead_time?.uuid || "",
                color: config?.color?.uuid || ""
              }
            }
          },
          country,
          technologies
        }
      }).unwrap()
      setSelectUnit(unit)
      toast.showSuccess("Resize model successfully")
    } catch (error: any) {
      const errMessage = error?.data?.message || "Failed to resize model"
      toast.showError(errMessage)
    }
  }

  const isShowIcon = useMemo(() => {
    return tagStatusClass[
      isProcessing ? EModelAnalysisStatus.LOADING : statusTag
    ]?.icon
  }, [isProcessing, statusTag])

  return (
    <Modal
      open={isModalOpen}
      title="Manufacturing Analysis"
      onCancel={() => onCancel()}
      footer={null}
      width={1307}
    >
      <div className="viewer-modal-wrapper">
        <div className="viewer-modal">
          <div className="viewer-modal-infor">
            <div className="">
              <div className="viewer-modal-infor-content">
                <div className={`product-tag`}>
                  <p>
                    {isShowIcon ? (
                      <img
                        width={16}
                        height={16}
                        src={
                          isProcessing
                            ? tagStatusClass[EModelAnalysisStatus.LOADING]?.icon
                            : tagStatusClass[statusTag]?.icon
                        }
                        alt=""
                      />
                    ) : null}
                    <span>
                      {isProcessing
                        ? tagStatusCheckClass[EModelAnalysisStatus.LOADING]
                            ?.text
                        : tagStatusCheckClass[statusTag]?.text}
                    </span>
                  </p>
                  <div
                    className={`corner-bottom-right-bevel  ${
                      isProcessing
                        ? tagStatusCheckClass[EModelAnalysisStatus.LOADING]
                            ?.className
                        : tagStatusCheckClass[statusTag].className
                    }`}
                  ></div>
                </div>
                <div className="viewer-modal-infor-content-text">
                  <h3>{title}</h3>
                  {size && (
                    <>
                      <p>
                        <span>Size :</span>{" "}
                        {units === "mm"
                          ? Math.round(size?.x || 0)
                          : size?.x?.toFixed(2) || 0.0}{" "}
                        x{" "}
                        {units === "mm"
                          ? Math.round(size?.y || 0)
                          : size?.y?.toFixed(2) || 0.0}{" "}
                        x{" "}
                        {units === "mm"
                          ? Math.round(size?.z || 0)
                          : size?.z?.toFixed(2) || 0.0}{" "}
                        {units}
                      </p>

                      <div className="viewer-modal-infor-content-text-units">
                        <p>Units </p>{" "}
                        <PopOverSelect
                          selectedUnit={selectUnit}
                          handleResize={handleSelectedUnit}
                          status={statusTag || EModelAnalysisStatus.ERROR}
                        ></PopOverSelect>
                      </div>
                      <p>
                        <span>Volume: </span>{" "}
                        {volume?.toFixed(2)
                          ? ` ${volume?.toFixed(2) || 0.0} ${units}3`
                          : ""}
                      </p>
                    </>
                  )}
                </div>
              </div>

              <div className="viewer-modal-analysis">
                <DFMCheck
                  technology={technology || ""}
                  data={dfmAnalysisData}
                  analysis={analysis}
                ></DFMCheck>
              </div>
            </div>
          </div>
          <div className="viewer-modal-model">
            {loading ? (
              <div className="viewer-modal-model-loading">
                <Loading />
              </div>
            ) : (
              <Model
                src={file_model_viewer_url || ""}
                wallThicknessFileUrl={analysis?.result?.thin_faces_file_url}
              />
            )}
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default ViewerModal
