import {
  ContactUsActiveIcon,
  ContactUsIcon,
  NewQuote,
  NewQuoteActive,
  Orders,
  OrdersActive,
  Quotes,
  QuotesActive
} from "@/assets"
import { TabType } from "@/enums/common.enum"
import { FieldError } from "react-hook-form"

export const TABS = [
  {
    name: "New Quote",
    imgSrc: NewQuote,
    imgSrcActive: NewQuoteActive,
    routerLink: "/new-quote",
    type: TabType.NEW_QUOTE
  },
  {
    name: "Quotes",
    imgSrc: Quotes,
    imgSrcActive: QuotesActive,
    routerLink: "/quotes",
    type: TabType.QUOTES
  },
  {
    name: "Orders",
    imgSrc: Orders,
    imgSrcActive: OrdersActive,
    routerLink: "/orders",
    type: TabType.ORDERS
  },
  {
    name: "Contact us",
    imgSrc: ContactUsIcon,
    imgSrcActive: ContactUsActiveIcon,
    routerLink: "/contact-us",
    type: TabType.CONTACT_US
  }
]

export const PROFILE_TABS = [
  {
    name: "Personal Information",
    routerLink: "/profile/infor"
  },
  {
    name: "Shipping Address",
    routerLink: "/profile/shipping-address"
  },
  {
    name: "Billing Address",
    routerLink: "/profile/billing-address"
  }
]

export const REGEX_EMAIL =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const REGEX_PASSWORD =
  /^(?=.*[1-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)[A-Za-z\d\W]{6,32}$/
export const REGEX_ONLY_NUMBER = /^\d+$/
export const REGEX_PHONE_NUMBER = /^\+?\d+$/
export const REGEX_PHONE_NUMBER_DASH =
  /^(?:\d{3}[-]\d{3}[-]\d{4}|\d{3}\s\d{3}\s\d{4}|\d{10})$/
export const METHOD_AND_NUMBER = /(\w+):\s*(\d*)/
export const REQUIRED_ERROR = (fieldName: string) => `${fieldName} is required`
export const REQUIRED_ERROR_FIELD = () => `Required field`

export const DEFAULT_WIDTH = 250
export const DEFAULT_COUNTRY_ID = 39
export const DEFAULT_US_ID = 233

export const DEFAULT_US = {
  name: "United States",
  iso2: "US",
  id: DEFAULT_US_ID
}

export const DEFAULT_CANADA = {
  name: "Canada",
  iso2: "CA",
  id: DEFAULT_COUNTRY_ID
}

export const errorStatus = (field: FieldError | undefined) => {
  return field?.message ? "error" : ""
}

export const filePattern = /\.(step|stp|iges|igs|sldprt|3dm|sat|x_t|glb|stl)$/i
export const fileSupportPattern =
  /\.(stl|obj|wrl|step|stp|iges|igs|3mf|dxf|dwg)$/i

export const EMTY_STATE = { iso2: "", name: "", id: "" }

export const DIGIFABSTER_INVOICE_URL = "https://digifabster.com/client/invoice"

export const DATE_RANGE_FORMATS = {
  shortWithNoComma: "EEE MMM d",
  shortWithComma: "EEE, MMM d",
  longWithNoComma: "EEEE MMM d",
  longWithComma: "EEEE, MMM d"
}

export const PHONE_NUMBER_CONTACT = "1-877-329-9131"
