import { useEffect, useMemo, useState } from "react"
import RootContainer from "@/components/RootContainer/RootContainer"
import QuotesListTable from "./components/QuotesListTable"
import { Pagination } from "@/components"
import { useNavigate, useSearchParams } from "react-router-dom"
import { useGetListQuoteQuery } from "@/services/apiDigifabster/quote"
import { useAppLoading } from "@/hooks/useLoading"
import "./QuotesPage.scss"
import { EOrderStatus } from "@/enums/quotesList.enum"
import { priceDisplay } from "@/utils/functionHelper"
import { useCurrency } from "@/hooks/useCurrency"
import { Quotes } from "@/interfaces/table.interface"

export default function QuotesPage(): JSX.Element {
  const [searchParams, setSearchParams] = useSearchParams()
  const [currentPage, setCurrentPage] = useState<number>(
    Number(searchParams.get("page")) || 1
  )
  const [pageSize, setPageSize] = useState<number>(
    Number(searchParams.get("limit")) || 15
  )
  const navigate = useNavigate()
  const { currency } = useCurrency()

  const { data, isError, isLoading, isFetching, refetch } =
    useGetListQuoteQuery({
      limit: pageSize,
      offset: (currentPage - 1) * pageSize,
      ordering: "-date",
      status__in: [
        EOrderStatus.WAITING_FOR_REVIEW,
        EOrderStatus.FIRM_OFFER_SENT,
        EOrderStatus.CANCEL_BY_ADMIN,
        EOrderStatus.CANCEL_BY_USER
      ],
      status__not__in: [],
      expand: ["main_invoice"]
    })

  useAppLoading([isLoading, isFetching])

  useEffect(() => {
    const loadingOrdersList = async () => {
      setSearchParams({
        page: currentPage.toString(),
        limit: pageSize.toString()
      })
      await refetch()
    }
    loadingOrdersList()
  }, [currentPage, pageSize, refetch])

  const quoteData: Quotes[] = useMemo(() => {
    return (
      data?.results?.map((quote) => ({
        no: quote.company_order_id,
        orderDate: quote.date,
        total: `${currency}${priceDisplay(
          quote.total_price - quote.startup_cost
        )}`,
        status: quote.status as EOrderStatus,
        statusDisplay: quote.status_display,
        id: quote.id,
        invoice_id: quote?.main_invoice?.id || 0,
        invoice_hash: quote?.main_invoice?.pay_url.split("/").pop() || ""
      })) || []
    )
  }, [data, currentPage, pageSize])

  const handleSizeChange = (current: number, size: number) => {
    handleChange(1)
    setPageSize(size)
  }

  const onDetail = (
    invoiceId: number,
    invoiceHash: string,
    status: string,
    id: number
  ) => {
    if (!invoiceId || !invoiceHash) return
    navigate(
      `/quotes/invoices/${invoiceId}/${invoiceHash}?page=${currentPage}&limit=${pageSize}`
    )
  }

  const handleChange = (page: number) => {
    setCurrentPage(page)
  }

  if (!data || isFetching) return <></>

  return (
    <RootContainer headerText={`All Quotes (${data?.count || 0})`}>
      <div className="quote-page">
        <div className="quote-container">
          <QuotesListTable data={quoteData} onDetail={onDetail} />
          <div className="order-container-pagination">
            {!!data?.count && (
              <Pagination
                current={currentPage}
                pageSize={pageSize}
                total={data?.count || 0}
                onChange={handleChange}
                onShowSizeChange={handleSizeChange}
              />
            )}
          </div>
        </div>
      </div>
    </RootContainer>
  )
}
