import { Order } from "@/interfaces/table.interface"
import { Button, CustomBadge, Table } from "@/components"
import { ColumnType } from "antd/es/table"
import {
  Size,
  ButtonHierarchy,
  BadgeType,
  BadgeColor
} from "@/enums/common.enum"
import { quotesListColumn } from "@/constants/quoteListTable.constant"
import { EOrderStatus } from "@/enums/quotesList.enum"

interface OrderListTableProps {
  data: Order[]
  onDetail: (invoiceId: number, invoiceHash: string) => void
}

const OrderListTable = ({
  data,
  onDetail
}: OrderListTableProps): JSX.Element => {
  const orderListColumns: ColumnType<Order>[] = [
    ...quotesListColumn,
    {
      title: <div className="status-col">STATUS</div>,
      key: "quoteStatus",
      width: "25%",
      render: (_: any, record: Order) => (
        <div className="status-col">
          <CustomBadge
            content={record.quoteStatus}
            type={BadgeType.TONAL}
            size={Size.MEDIUM}
            color={
              record.status === EOrderStatus.PLACED
                ? BadgeColor.SUCCESS
                : BadgeColor.ROLE
            }
          />
        </div>
      )
    },
    {
      title: "",
      key: "action",
      width: "15%",
      render: (_: any, record: Order) => (
        <div style={{ textAlign: "center" }}>
          <Button
            customSize={Size.EXTRA_SMALL}
            hierarchy={ButtonHierarchy.TONAL}
            onClick={() => onDetail(record?.invoice_id, record?.invoice_hash)}
          >
            Details
          </Button>
        </div>
      )
    }
  ]
  return <Table data={data} columns={orderListColumns} rowKey="id" />
}

export default OrderListTable
