import "./EditSpecificationDrawer.scss"
import "./EditSpecificationForm.scss"
import { useSelector } from "react-redux"
import { RootState } from "@/store"
import { Controller, useForm } from "react-hook-form"
import {
  IEditSpecificationsForm,
  IPostProductionData
} from "@/interfaces/editSpecifications.interface"
import { BadgeColor, BadgeType, Size, Type } from "@/enums/common.enum"
import {
  FormField,
  SelectDropdown,
  NumberField,
  Button,
  UploadFiles,
  CustomBadge,
  Loading
} from "@/components"
import { IQuoteStore } from "@/store/quote"
import {
  EModelAnalysisStatus,
  IAvailableTechnology,
  IMaterial,
  IModelAnalysis,
  IProductStore
} from "@/store/product"
import { createElement, useEffect, useMemo } from "react"
import {
  LineItem,
  Product,
  useUpdateProductMutation
} from "@/services/apiDigifabster/quote"
import { EDrawings } from "@/enums/createQuote.enum"
import * as toast from "@/utils/Toast"
import { UploadFile } from "antd"
import { priceDisplay } from "@/utils/functionHelper"
import { TOLERANCE_TEXT } from "@/constants/editSpecifications.constant"
import { errorStatus } from "@/constants/common.constant"
import { useCurrency } from "@/hooks/useCurrency"
import { IUserStore } from "@/store/user"
import PostProductionDropdown from "./components/PostProductionDropdown/PostProductionDropdown"
import { PDFIcon } from "@/assets"
import { CloseOutlined } from "@ant-design/icons"
import { MANUAL_REQUEST_MATERIAL_IDS } from "@/constants/order.constrant"
import { EOrderStatus } from "@/enums/quotesList.enum"
import { EManufacturingProcess } from "@/enums/editSpecifications.enum"
import { EDIT_SPECIFICATIONS_SCHEMA } from "@/validations/editSpecifications.validation"
import { yupResolver } from "@hookform/resolvers/yup"
import { useEditSpecification } from "@/hooks/useEditSpecification"
interface IEditSpecificationDrawerProps {
  onUpdateDone?: () => void
  openEditDrawer?: boolean
  isMultiple?: boolean
  suitMaterialsMultiple: IAvailableTechnology[]
  setSelectedItems: React.Dispatch<React.SetStateAction<LineItem[]>>
  setIsLoadingDelete: React.Dispatch<React.SetStateAction<boolean>>
  setIsLoadingUpdateProduct: (ids: number[], isLoading: boolean) => void
}

export interface IEditSpecification {
  postProduction?: IPostProductionData[] | undefined
  postProductionCount?: number | undefined
  material: number
  filling: string
  color: string
  manufacturingProcess: number
  layerHeight: string
  quantity: number
  leadTime: string
}
const EditSpecificationDrawer: React.FC<IEditSpecificationDrawerProps> = ({
  openEditDrawer,
  onUpdateDone,
  setIsLoadingDelete,
  setIsLoadingUpdateProduct,
  suitMaterialsMultiple,
  isMultiple
}) => {
  const { suitableMaterial, technologies } = useSelector<
    RootState,
    IProductStore
  >((s) => s.product)
  const {
    currentQuote,
    quoteDetail,
    selectedProduct: product,
    currentPriceProduct
  } = useSelector<RootState, IQuoteStore>((s) => s.quote)
  const { userInfoNew } = useSelector<RootState, IUserStore>((s) => s.user)
  const modelAnalysis = useSelector<RootState, IModelAnalysis | undefined>(
    (s) => s.product.modelsAnalysis[product?.model_id || 0]
  )
  const modelAnalysisIncludeReview = useMemo(() => {
    const findProduct = quoteDetail?.line_items?.find(
      (e) => e.id === product?.id
    )
    return findProduct?.status === EOrderStatus.WAITING_FOR_REVIEW
      ? findProduct?.self_notes?.includes("placeholder file")
        ? { ...modelAnalysis, status: EModelAnalysisStatus.REVIEW }
        : modelAnalysis
      : modelAnalysis
  }, [modelAnalysis, product, quoteDetail, openEditDrawer])
  const country = userInfoNew?.country || ""
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    setError,
    formState: { errors }
  } = useForm<IEditSpecification>({
    defaultValues: {},
    resolver: yupResolver(EDIT_SPECIFICATIONS_SCHEMA)
  })

  const [updateProduct, { isLoading, isSuccess, isError }] =
    useUpdateProductMutation()

  const {
    getPostProductionList,
    getPrices,
    getTextPlaceHolder,
    handlePrefillMaterials,
    handlePrefillTechnology,
    setColorPrefix,
    handlePrefillData,
    submitFile,
    removeFile,
    isPriceLoading,
    allTechnologies,
    fileList,
    materials,
    colors,
    layerHeights,
    inFills,
    postProduction,
    leadTimes,
    currentPrefix,
    disable,
    isDeleteFile,
    isUploadingFile
  } = useEditSpecification()

  useEffect(() => {
    if (!isLoading && isSuccess && onUpdateDone) {
      onUpdateDone()
      reset()
    }
  }, [isSuccess])

  const watchTech = watch("manufacturingProcess")
  const watchMaterial = watch("material")
  const watchColor = watch("color")
  const watchQuanity = watch("quantity")
  const watchLayerHeight = watch("layerHeight")
  const watchFiling = watch("filling")
  const watchPostProduction = watch("postProduction")
  const watchLeadTime = watch("leadTime")

  const currentMaterial = useMemo(() => {
    return materials.find((e) => e.id === watchMaterial)
  }, [watchMaterial])

  useEffect(() => {
    if (!isLoading && isError) {
      toast.showError("Failed to update product")
    }
  }, [isError])
  const technologyItemOptionHeight = 70
  const { currency } = useCurrency()

  useEffect(() => {
    getPrices({
      watchMaterial,
      watchLayerHeight,
      watchColor,
      watchLeadTime,
      watchFiling,
      watchQuanity,
      watchPostProduction,
      product
    })
  }, [
    watchQuanity,
    watchLayerHeight,
    watchFiling,
    watchColor,
    watchLeadTime,
    watchPostProduction
  ])

  useEffect(() => {
    setColorPrefix(watchColor)
  }, [watchColor, colors])

  useEffect(() => {
    if (!watchTech || !product) return

    const tech = technologies.find((e) => e.id === watchTech)
    if (!tech) return
    const { material } = handlePrefillMaterials(
      suitMaterialsMultiple,
      suitableMaterial,
      product,
      watchTech,
      isMultiple,
      tech
    )

    setError("manufacturingProcess", {})
    setValue("material", material)
  }, [watchTech])

  useEffect(() => {
    if (!watchMaterial || !product) return

    const material = materials.find((e) => e.id === watchMaterial)
    if (!material) return

    const {
      isProductMaterial,
      prefillQuantity,
      colorList,
      layerHeightList,
      leadTimeList,
      fillingList
    } = handlePrefillData(product, material)
    if (colorList && colorList.length) {
      const prefillColor = isProductMaterial
        ? product?.config?.color.uuid
        : colorList[0].uuid
      setValue("color", prefillColor)
    }
    if (layerHeightList && layerHeightList.length) {
      const prefilllayerHeight = isProductMaterial
        ? product?.config?.layer_thickness.uuid
        : layerHeightList[0].uuid
      setValue("layerHeight", prefilllayerHeight)
    }

    if (leadTimeList && leadTimeList.length) {
      const prefillLeadTime = isProductMaterial
        ? product?.config?.lead_time.uuid
        : leadTimeList[0].uuid
      setValue("leadTime", prefillLeadTime)
    }

    if (fillingList && fillingList.length) {
      const prefillFilling = isProductMaterial
        ? product?.config?.filling.uuid
        : fillingList[0].uuid
      setValue("filling", prefillFilling)
    }

    if (material.post_production.length > 0) {
      fetchPostProduction(material, product)
    }

    setValue("quantity", prefillQuantity)
  }, [watchMaterial])

  const fetchPostProduction = async (material: IMaterial, product: Product) => {
    const { prefillPostProduction } = await getPostProductionList(
      material,
      country,
      product
    )

    setValue("postProduction", prefillPostProduction)
  }

  useEffect(() => {
    reset()
    if (!product || !openEditDrawer) return

    const { selectedTech } = handlePrefillTechnology(
      technologies,
      product,
      suitMaterialsMultiple,
      isMultiple
    )

    if (!selectedTech) return

    setValue("manufacturingProcess", selectedTech.id, {
      shouldDirty: false,
      shouldTouch: false
    })
  }, [product, openEditDrawer])

  const onSubmit = async (data: IEditSpecificationsForm) => {
    if (
      !currentQuote ||
      !currentQuote.id ||
      !product ||
      !product.id ||
      !quoteDetail ||
      !quoteDetail.id
    )
      return

    const config:
      | Record<string, string>
      | Record<string, IPostProductionData[]> = {}
    if (data.color) config["color"] = data.color
    if (data.layerHeight) config["layer_thickness"] = data.layerHeight
    if (data.filling) config["filling"] = data.filling
    if (data.postProduction) config["post_production"] = data.postProduction
    if (data.leadTime) config["lead_time"] = data.leadTime
    onUpdateDone?.()
    const selectedItems = product.ids
    if (isMultiple && onUpdateDone) {
      const idsToUpdate = selectedItems.map((item) => item.id)
      setIsLoadingUpdateProduct(idsToUpdate, true)
      const idsToRemove: number[] = [] // Collect the IDs of successfully deleted items

      for (const item of selectedItems) {
        try {
          await updateProduct({
            orderId: currentQuote.id,
            productId: item?.id,
            arg: {
              country: userInfoNew?.country || "",
              payload: {
                config,
                count: data.quantity,
                material_id: data.material,
                model_id: item?.model_id,
                suitable_materials: materials
              }
            }
          })
          idsToRemove.push(item.id)
        } catch (_) {}
      }

      // Remove the successfully deleted items from the selected items

      setIsLoadingUpdateProduct(idsToUpdate, false)

      reset()
    } else {
      setIsLoadingUpdateProduct([product?.id], true)
      await updateProduct({
        orderId: currentQuote.id,
        productId: product?.id,
        arg: {
          country: userInfoNew?.country || "",
          payload: {
            config,
            count: data.quantity,
            material_id: data.material,
            model_id: product.model_id,
            suitable_materials: materials
          }
        }
      })
      setIsLoadingUpdateProduct([product?.id], false)
    }
    setIsLoadingDelete(false)
  }

  const isHidePrice = useMemo(() => {
    return (
      isPriceLoading ||
      disable ||
      ((modelAnalysisIncludeReview?.status === EModelAnalysisStatus?.ERROR ||
        modelAnalysisIncludeReview?.status === EModelAnalysisStatus?.REVIEW) &&
        currentPriceProduct?.errorAnalysisText) ||
      MANUAL_REQUEST_MATERIAL_IDS.includes(watchMaterial)
    )
  }, [
    isPriceLoading,
    disable,
    modelAnalysisIncludeReview,
    product,
    watchMaterial
  ])

  const isReviewProduct = useMemo(
    () => modelAnalysisIncludeReview?.status === EModelAnalysisStatus.REVIEW,
    [modelAnalysisIncludeReview]
  )

  const handleUploadFiles = async (info: {
    file: UploadFile
    fileList: UploadFile[]
  }) => {
    await submitFile(info, currentQuote?.id || 0, product?.id || 0)
  }

  const handleRemoveFile = async (file: UploadFile) => {
    removeFile(file, currentQuote?.id || 0, product?.id || 0)
  }

  const onChangePostProduction = (
    newPostProductionData: IPostProductionData[]
  ) => {
    setValue("postProduction", newPostProductionData)
  }

  if (!product || !currentQuote || !suitableMaterial[product?.parent_model_id])
    return <></>

  return (
    <form className="edit-specification-form" onSubmit={handleSubmit(onSubmit)}>
      <div className="edit-specification-form-section">
        <FormField
          clasName="edit-specification-form-label"
          textLabel="Manufacturing process"
          errorText={errors?.manufacturingProcess?.message}
        >
          <Controller
            name="manufacturingProcess"
            control={control}
            render={({ field: { ref, ...others } }) => (
              <div className="edit-specification-form-material-dropdown">
                <SelectDropdown
                  {...others}
                  width={"100%"}
                  listHeight={
                    allTechnologies.length * technologyItemOptionHeight
                  }
                  status={errorStatus(errors.manufacturingProcess)}
                  placeholder="Please select"
                  virtual={false}
                  dropdownClassName="edit-specification-form-dropdown"
                  prefixIcon={
                    <img
                      loading="lazy"
                      width={20}
                      height={20}
                      src={currentPrefix.technology}
                      alt=""
                    />
                  }
                  options={allTechnologies.map((e) => ({
                    id: e.id,
                    title: e.title,
                    subTitle: e.note,
                    prefix: createElement("img", {
                      src: e.image,
                      width: 20,
                      height: 20
                    })
                  }))}
                />
                <a href="#">Details</a>
              </div>
            )}
          />
        </FormField>

        <FormField
          clasName="edit-specification-form-label"
          textLabel="Material"
          errorText={errors?.material?.message}
        >
          <Controller
            name="material"
            control={control}
            render={({ field: { ref, ...others } }) => (
              <div className="edit-specification-form-material-dropdown">
                <SelectDropdown
                  {...others}
                  width={"100%"}
                  placeholder="Please select"
                  virtual={false}
                  dropdownClassName="edit-specification-form-dropdown"
                  prefixIcon={
                    <img
                      loading="lazy"
                      width={16}
                      height={16}
                      src={currentPrefix.technology}
                      alt=""
                    />
                  }
                  options={materials.map((e) => ({
                    id: e.id,
                    title: e.title,
                    prefix: createElement("img", {
                      src: currentPrefix.technology,
                      width: 16,
                      height: 16
                    })
                  }))}
                />
                {materials.find((e) => e.id === others.value)
                  ?.spec_sheet_url ? (
                  <a
                    href={
                      materials.find((e) => e.id === others.value)
                        ?.spec_sheet_url
                    }
                    target="_blank"
                  >
                    Spec sheet
                  </a>
                ) : (
                  <a className="disable">Spec sheet</a>
                )}
              </div>
            )}
          />
        </FormField>

        {colors.length ? (
          <FormField
            clasName="edit-specification-form-label"
            textLabel="Color"
            errorText={errors?.color?.message}
          >
            <Controller
              name="color"
              control={control}
              render={({ field: { ref, ...others } }) => (
                <SelectDropdown
                  {...others}
                  width={"100%"}
                  placeholder="Please select"
                  virtual={false}
                  dropdownClassName="edit-specification-form-dropdown"
                  prefixIcon={
                    <div
                      style={{
                        backgroundColor: currentPrefix.color,
                        width: 16,
                        height: 16,
                        borderRadius: "50%",
                        border:
                          currentPrefix.color === "#FFFFFF"
                            ? "1px solid var(--border-gray-strong)"
                            : "none"
                      }}
                    />
                  }
                  options={colors.map((e) => ({
                    id: e.uuid,
                    title: e.title,
                    prefix: createElement("div", {
                      style: {
                        backgroundColor: e.color,
                        width: 16,
                        height: 16,
                        borderRadius: "50%",
                        border:
                          e.color === "#FFFFFF"
                            ? "1px solid var(--border-gray-strong)"
                            : "none"
                      }
                    })
                  }))}
                  disabled={colors.length === 1}
                />
              )}
            />
          </FormField>
        ) : (
          <></>
        )}
        <div className="edit-specification-form-row">
          <FormField
            clasName="edit-specification-form-label"
            textLabel="Layer Height"
            errorText={errors?.layerHeight?.message}
          >
            <Controller
              name="layerHeight"
              control={control}
              render={({ field: { onChange, value } }) => (
                <SelectDropdown
                  width={"100%"}
                  placeholder="Please select"
                  onChange={onChange}
                  value={value}
                  dropdownClassName="edit-specification-form-dropdown"
                  options={layerHeights.map((e) => ({
                    id: e.uuid,
                    title: e.title,
                    subTitle: e.note_for_user
                  }))}
                  disabled={layerHeights.length === 1}
                />
              )}
            />
          </FormField>
          {!!inFills.length && (
            <FormField
              clasName="edit-specification-form-label"
              textLabel="Infill"
              errorText={errors?.filling?.message}
            >
              <Controller
                name="filling"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <SelectDropdown
                    width={"100%"}
                    placeholder="Please select"
                    onChange={onChange}
                    value={value}
                    dropdownClassName="edit-specification-form-dropdown"
                    options={inFills.map((e) => ({
                      id: e.uuid,
                      title: e.title,
                      subTitle: e.note_for_user
                    }))}
                    disabled={inFills.length === 1}
                  />
                )}
              />
            </FormField>
          )}
        </div>

        <div className="edit-specification-form-row">
          <FormField
            clasName="edit-specification-form-label edit-specification-form-production-time"
            textLabel="Production Time"
            errorText={errors?.leadTime?.message}
          >
            <Controller
              name="leadTime"
              control={control}
              render={({ field: { onChange, value } }) => (
                <SelectDropdown
                  width={"100%"}
                  placeholder="Please select"
                  onChange={onChange}
                  value={value}
                  options={leadTimes.map((e) => ({
                    id: e.uuid,
                    title: e.title,
                    subTitle: e.note_for_user
                  }))}
                  dropdownClassName="edit-specification-form-dropdown"
                  disabled={leadTimes.length === 1}
                />
              )}
            />
          </FormField>
        </div>

        <div className="edit-specification-form-row">
          <FormField
            clasName="edit-specification-form-label"
            textLabel="Quantity"
            errorText={errors?.quantity?.message}
          >
            <Controller
              name="quantity"
              control={control}
              render={({ field: { onChange, value } }) => (
                <NumberField
                  disable={disable}
                  value={value}
                  setValue={onChange}
                  type={Type.NEUTRAL}
                  customClassName="edit-specification-form-quantity"
                  size={Size.EXTRA_SMALL}
                />
              )}
            />
          </FormField>
        </div>

        {!!currentMaterial?.post_production?.length && (
          <Controller
            name="postProduction"
            control={control}
            render={({ field: { value } }) => (
              <>
                {Object.keys(postProduction).map((item) => (
                  <div key={item}>
                    <FormField
                      clasName="edit-specification-form-label post-label"
                      textLabel={`${item}`}
                    >
                      <PostProductionDropdown
                        options={postProduction[item]}
                        value={isMultiple ? undefined : value}
                        currency={currency}
                        placeholder={getTextPlaceHolder(item)}
                        onChangePostProduction={onChangePostProduction}
                        isMultiple={isMultiple}
                      />
                      <div className="dropdown-learn-more">
                        Learn more about{" "}
                        <a
                          href="https://forgelabs.com/post-processing-services/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {item.toLowerCase()}{" "}
                          {item === "Post Production" && "options"}
                        </a>
                      </div>
                    </FormField>
                  </div>
                ))}
              </>
            )}
          />
        )}
      </div>
      <div className="edit-specification-form-action">
        <Button
          htmlType="submit"
          customSize={Size.LARGE}
          customClassName="edit-specification-form-action-btn"
          disabled={isPriceLoading || isUploadingFile || isDeleteFile}
        >
          Apply
        </Button>
      </div>

      {!isMultiple && (
        <div className="edit-specification-form-price">
          <div>
            <div className="unit-price">
              <span className="unit-price-title">Unit price</span>
              <span className="unit-price-value">
                {isHidePrice || isReviewProduct ? "" : currency}
                {isHidePrice
                  ? "-.--"
                  : currentPriceProduct?.price_per_part
                  ? isReviewProduct
                    ? "-.--"
                    : priceDisplay(currentPriceProduct?.price_per_part || 0)
                  : "-.--"}
              </span>
            </div>
            <div className="total-price">
              <span className="total-price-title">
                Total price{" "}
                {watchQuanity && watchQuanity > 0
                  ? `(${watchQuanity} ${`${
                      watchQuanity > 1 ? "items" : "item"
                    }`})`
                  : ""}
              </span>
              <span className="total-price-value">
                {isHidePrice || isReviewProduct ? "" : currency}
                {isHidePrice
                  ? !isPriceLoading
                    ? "Requires Review"
                    : "-.--"
                  : currentPriceProduct?.total_price
                  ? isReviewProduct
                    ? "Requires Review"
                    : priceDisplay(currentPriceProduct?.total_price || 0)
                  : isReviewProduct
                  ? "Requires Review"
                  : "-.--"}
              </span>
            </div>
          </div>
        </div>
      )}
      {!!watchTech && watchTech !== EManufacturingProcess.DP && (
        <div className="edit-specification-form-tolerances">
          <p className="edit-specification-form-tolerances-title">Tolerances</p>
          <p className="edit-specification-form-tolerances-subtitle">
            {TOLERANCE_TEXT[watchTech as keyof typeof TOLERANCE_TEXT]?.text}
            <span>
              <a
                href={
                  TOLERANCE_TEXT[watchTech as keyof typeof TOLERANCE_TEXT]?.url
                }
                target="_blank"
              >
                {" "}
                See full design guidelines{" "}
              </a>
              for details
            </span>
          </p>
        </div>
      )}

      {currentMaterial?.acceptDrawing === EDrawings.ACCEPT_NOT_REQUIRE && (
        <div className="edit-specification-form-upload">
          {fileList?.length === 0 && !isUploadingFile ? (
            <UploadFiles
              listFiles={fileList}
              onUploadFiles={handleUploadFiles}
              uploadText="Upload Engineering Drawings"
              onRemove={handleRemoveFile}
              isShowList={false}
            />
          ) : !isMultiple ? (
            <>
              <CustomBadge
                type={BadgeType.TONAL}
                color={BadgeColor.ROLE}
                size={Size.MEDIUM}
                content=""
              >
                <div
                  key={fileList[0].uid}
                  className="edit-specification-form-upload-render-file"
                >
                  <img src={PDFIcon} alt="" />
                  <p>{fileList[0].name}</p>
                  <div className="icon-box">
                    {isUploadingFile ? (
                      <Loading></Loading>
                    ) : (
                      <CloseOutlined
                        onClick={() => handleRemoveFile(fileList[0])}
                        className="icon-box-render"
                      />
                    )}
                  </div>
                </div>
              </CustomBadge>
              <p className="edit-specification-form-upload-text">
                The CAD file and specifications you select for this part will
                take precedence over technical drawings.
              </p>
            </>
          ) : (
            <></>
          )}
        </div>
      )}
    </form>
  )
}

export default EditSpecificationDrawer
