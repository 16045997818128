import "./BillingAddressForm.scss"
import { Controller, FieldError, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { IShippingForm } from "@/interfaces/checkout.interface"
import { SHIPPING_ADDRESS_SCHEMA } from "@/validations/delivery.validation"
import {
  Button,
  CountrySelect,
  FormField,
  SelectDropdown,
  TextField
} from "@/components"
import {
  DEFAULT_CANADA,
  DEFAULT_US,
  EMTY_STATE,
  errorStatus
} from "@/constants/common.constant"
import { CountryOptions, IState } from "@/interfaces/common.interface"
import { useEffect, useState } from "react"
import { getStatesList } from "@/constants/countries"
import { RootState } from "@/store"
import { IUserStore } from "@/store/user"
import { useSelector } from "react-redux"
import { ButtonHierarchy, Type } from "@/enums/common.enum"
import { useUpdateAddressMutation } from "@/services/apiDigifabster/user"
import { useAppLoading } from "@/hooks/useLoading"
import { showError, showSuccess } from "@/utils/Toast"

interface IShippingFormProps {
  setEdit: (isEdit: boolean) => void
}
function BillingAddressForm({ setEdit }: IShippingFormProps) {
  const [stateList, setStateList] = useState<CountryOptions[]>([])
  const [countriesList, setCountriesList] = useState<CountryOptions[]>([])
  const { userInfoNew } = useSelector<RootState, IUserStore>((s) => s.user)
  const [updateAddress, { isLoading, isSuccess, isError }] =
    useUpdateAddressMutation()

  const DEFAULT_COUNTRY =
    userInfoNew?.country === "US" ? DEFAULT_US : DEFAULT_CANADA

  useAppLoading([isLoading])

  const handleSave = async (data: IShippingForm) => {
    const mapShippingFormToPayload = {
      name: data.name,
      surname: data.surname,
      phone: data.phoneNumber,
      email: data.email,
      country: data.country.iso2 || "",
      state: data.state.iso2 || "",
      city: data.city,
      address_type: "customer_company",
      postcode: data.zipCode,
      street_address: data.addressLine1,
      apartment: data.addressLine2,
      company_name: data.company
    }

    await updateAddress(mapShippingFormToPayload)
  }

  useEffect(() => {
    if (isSuccess) {
      setEdit(false)
      showSuccess("Address Infomation updated successfully", "")
    }

    if (isError) {
      showError("Address Infomation updated unsuccessfully", "")
    }
  }, [isSuccess, isError])

  const defaultDeliveryFormValues: IShippingForm = {
    name: userInfoNew?.last_billing_info?.name || userInfoNew?.name,
    surname: userInfoNew?.last_billing_info?.surname || userInfoNew?.surname,
    phoneNumber:
      userInfoNew?.last_billing_info?.phone || userInfoNew?.phone_number,
    email: userInfoNew?.last_billing_info?.email || userInfoNew?.email,
    city: userInfoNew?.billing_addresses?.[0]?.city,
    state: userInfoNew?.billing_addresses?.[0]?.state || ({} as IState),
    addressLine1: userInfoNew?.billing_addresses?.[0]?.street_address,
    addressLine2: userInfoNew?.billing_addresses?.[0]?.apartment,
    zipCode: userInfoNew?.billing_addresses?.[0]?.postcode,
    country: DEFAULT_COUNTRY,
    company: userInfoNew?.company_name
  }

  const {
    control,
    handleSubmit,
    watch,
    setError,
    setValue,
    reset,
    formState: { errors, isDirty }
  } = useForm<IShippingForm>({
    defaultValues: defaultDeliveryFormValues,
    resolver: yupResolver(SHIPPING_ADDRESS_SCHEMA)
  })
  const acceptCountries = ["CA", "US"]

  useEffect(() => {
    if (!isDirty) {
      const prefillCountry = countriesList.find(
        (item) => item.iso2 === userInfoNew?.billing_addresses?.[0]?.country
      )
      const mapprefillCountry = {
        name: prefillCountry?.title || DEFAULT_COUNTRY.name,
        id: prefillCountry?.id || DEFAULT_COUNTRY.id,
        iso2: prefillCountry?.iso2 || DEFAULT_COUNTRY.iso2
      }
      setValue(
        "country",
        mapprefillCountry || {
          id: DEFAULT_COUNTRY.id,
          name: DEFAULT_COUNTRY.name,
          iso2: DEFAULT_COUNTRY.iso2
        }
      )
    }
  }, [countriesList, isDirty])

  useEffect(() => {
    const getStates = async (countryId: number) => {
      const states = await getStatesList(countryId)
      setStateList(states)
      if (!isDirty) {
        const prefillState = states.find(
          (item) => item.iso2 === userInfoNew?.billing_addresses?.[0]?.state
        )
        const mapprefillState = {
          name: prefillState?.title || "",
          id: prefillState?.id || "",
          iso2: prefillState?.iso2 || ""
        }
        setValue("state", mapprefillState || EMTY_STATE)
      }
    }

    const countryId = watch("country").id
    if (countryId) {
      getStates(Number(countryId))
      return
    }
    setStateList([])
  }, [watch("country")])

  const handleChangeState = (
    stateId: string,
    onChangeCallback: (...event: any[]) => void
  ) => {
    const state = stateList.find((country) => country.id === stateId)
    const data = { iso2: state?.iso2, name: state?.title, id: state?.id }
    onChangeCallback(data || "")
  }

  const handleChangeCountry = (
    countryId: string,
    onChangeCallback: (...event: any[]) => void
  ) => {
    const state = countriesList.find((country) => country.id === countryId)
    const data = { iso2: state?.iso2, name: state?.title, id: state?.id }
    onChangeCallback(data || {})
  }

  useEffect(() => {
    const getStates = async (countryId: number) => {
      const states = await getStatesList(countryId)
      setStateList(states)
    }

    const countryId = watch("country")?.id
    if (countryId) {
      getStates(Number(countryId))
      return
    }
    setStateList([])
  }, [watch("country")])

  return (
    <div className="shipping-form-container">
      <form onSubmit={handleSubmit(handleSave)}>
        <p className="shipping-form-container-title">Required field *</p>
        <div className="shipping-form-container-row">
          <div className="shipping-form-container-col">
            <FormField textLabel="First name" errorText={errors.name?.message}>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    status={errorStatus(errors.name)}
                    placeholder="Enter your first name"
                  />
                )}
              />
            </FormField>
          </div>
          <div className="shipping-form-container-col">
            <FormField
              textLabel="Last name"
              errorText={errors.surname?.message}
            >
              <Controller
                name="surname"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    status={errorStatus(errors.surname)}
                    placeholder="Enter your last name"
                  />
                )}
              />
            </FormField>
          </div>
        </div>
        <div className="shipping-form-container-row">
          <div className="shipping-form-container-col">
            <FormField
              textLabel="Phone number *"
              errorText={errors.phoneNumber?.message}
            >
              <Controller
                name="phoneNumber"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    status={errorStatus(errors.phoneNumber)}
                    placeholder="Enter number"
                  />
                )}
              />
            </FormField>
          </div>
          <div className="shipping-form-container-col">
            <FormField
              textLabel="Email Address *"
              errorText={errors.email?.message}
            >
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    status={errorStatus(errors.email)}
                    placeholder="Enter Email"
                  />
                )}
              />
            </FormField>
          </div>
        </div>
        <p className="shipping-form-container-subtitle">Delivery Address</p>
        <div className="shipping-form-container-row">
          <div className="shipping-form-container-col">
            <FormField textLabel="Company " errorText={errors.company?.message}>
              <Controller
                name="company"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    status={errorStatus(errors.company)}
                    placeholder="Enter company name"
                  />
                )}
              />
            </FormField>
          </div>
        </div>
        <div className="shipping-form-container-row">
          <div className="shipping-form-container-col">
            <FormField
              textLabel="Address Line 1 *"
              errorText={errors.addressLine1?.message}
            >
              <Controller
                name="addressLine1"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    status={errorStatus(errors.addressLine1)}
                    placeholder="Enter address"
                  />
                )}
              />
            </FormField>
          </div>
          <div className="shipping-form-container-col">
            <FormField
              textLabel="Address Line 2"
              errorText={errors.addressLine2?.message}
            >
              <Controller
                name="addressLine2"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    status={errorStatus(errors.addressLine2)}
                    placeholder="Enter address"
                  />
                )}
              />
            </FormField>
          </div>
        </div>
        <div className="shipping-form-container-row">
          <div className="shipping-form-container-col">
            <FormField textLabel="City *" errorText={errors.city?.message}>
              <Controller
                name="city"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    status={errorStatus(errors.city)}
                    placeholder="Add city"
                  />
                )}
              />
            </FormField>
          </div>
          <div className="shipping-form-container-col">
            <FormField
              textLabel="State / Province *"
              errorText={errors.state?.message}
            >
              <Controller
                name="state"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <SelectDropdown
                    status={errorStatus(errors?.state as FieldError)}
                    width={"100%"}
                    dropdownClassName="dropdown-menu"
                    value={value?.id}
                    searchBar={true}
                    valueChangeToClear={watch("country")?.id}
                    onChange={(stateId) => handleChangeState(stateId, onChange)}
                    options={stateList}
                    virtual={false}
                    placeholder="Select state / province"
                  />
                )}
              />
            </FormField>
          </div>
        </div>
        <div className="shipping-form-container-row">
          <div className="shipping-form-container-col">
            <FormField
              textLabel="Zip Code *"
              errorText={errors.zipCode?.message}
            >
              <Controller
                name="zipCode"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    status={errorStatus(errors.zipCode)}
                    placeholder="Enter zip code"
                  />
                )}
              />
            </FormField>
          </div>
          <div className="shipping-form-container-col">
            <FormField textLabel="Country *">
              <Controller
                name="country"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <CountrySelect
                    countriesList={countriesList}
                    status={errorStatus(errors?.country as FieldError)}
                    setCountriesList={setCountriesList}
                    flag={`https://flagcdn.com/w20/${value?.iso2?.toLowerCase()}.png`}
                    value={value?.id}
                    width={"100%"}
                    dropdownClassName="dropdown-menu"
                    handleChange={(countryId) =>
                      handleChangeCountry(countryId, onChange)
                    }
                    acceptCountries={acceptCountries}
                    placeholder="Select country"
                  />
                )}
              />
            </FormField>
          </div>
        </div>
        <div className="shipping-form-container-btn">
          {!!userInfoNew?.billing_addresses?.length && (
            <Button
              htmlType="submit"
              customType={Type.NEUTRAL}
              hierarchy={ButtonHierarchy.LINK}
              customClassName="btn-edit"
              onClick={() => setEdit(false)}
            >
              Cancel
            </Button>
          )}
          <Button
            htmlType="submit"
            customClassName="btn-edit"
            onClick={handleSubmit(handleSave)}
          >
            Save
          </Button>
        </div>
      </form>
    </div>
  )
}

export default BillingAddressForm
