import {
  BillingAddressResponseDto,
  ShippingAddressResponseDto,
  UserResponseDto,
  userService
} from "@/services/api/user"

import { userService as userServiceNew } from "@/services/apiDigifabster/user"

import { createSlice, isAnyOf, PayloadAction } from "@reduxjs/toolkit"
import {
  CustomerInfoResponse,
  IAddressInfomationResponse,
  ICompanyStore,
  ISettingsResponse,
  UserInfoResponse
} from "./type"

export interface IUserStore {
  userInfo: UserResponseDto
  userInfoNew: UserInfoResponse
  shippingAddresses: ShippingAddressResponseDto[]
  shippingDetail?: ShippingAddressResponseDto
  billingAddress?: BillingAddressResponseDto
  settings: ISettingsResponse
  company: ICompanyStore
  customerInfo: CustomerInfoResponse
  userAddress: IAddressInfomationResponse
}
const userSlice = createSlice({
  name: "user",
  initialState: {
    userInfo: {} as UserResponseDto,
    userInfoNew: {} as UserInfoResponse,
    userAddress: {} as IAddressInfomationResponse,
    shippingAddresses: [],
    billingAddress: undefined,
    company: {} as ICompanyStore,
    settings: {} as ISettingsResponse,
    customerInfo: {} as CustomerInfoResponse
  } as IUserStore,
  reducers: {
    deleteShippingAddress: (state, { payload }: PayloadAction<string>) => {
      state.shippingAddresses = state.shippingAddresses.filter(
        (e) => e._id !== payload
      )
    },
    clearCustomerInfor: (state) => {
      state.customerInfo = {} as CustomerInfoResponse
    }
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(userServiceNew.endpoints.getClient.matchFulfilled),
      (state, { payload }) => {
        state.customerInfo = payload
      }
    ),
      builder.addMatcher(
        isAnyOf(userServiceNew.endpoints.updateAddress.matchFulfilled),
        (state, { meta, payload }) => {
          if (meta?.arg?.originalArgs?.address_type === "delivery") {
            state.userInfoNew = {
              ...state.userInfoNew,
              delivery_addresses: [
                payload,
                ...(state.userInfoNew?.delivery_addresses || [])
              ],
              last_delivery_info: {
                ...state.userInfoNew.last_delivery_info,
                name: meta?.arg?.originalArgs?.name || "",
                surname: meta?.arg?.originalArgs?.surname || "",
                phone: meta?.arg?.originalArgs?.phone,
                email: meta?.arg?.originalArgs?.email
              }
            }
          } else {
            state.userInfoNew = {
              ...state.userInfoNew,
              billing_addresses: [
                payload,
                ...(state.userInfoNew?.billing_addresses || [])
              ],
              last_billing_info: {
                ...state.userInfoNew.last_billing_info,
                name: meta?.arg?.originalArgs?.name || "",
                surname: meta?.arg?.originalArgs?.surname || "",
                phone: meta?.arg?.originalArgs?.phone,
                email: meta?.arg?.originalArgs?.email
              }
            }
          }
        }
      )
    builder.addMatcher(
      isAnyOf(
        userService.endpoints.getUser.matchFulfilled,
        userService.endpoints.updateUser.matchFulfilled
      ),
      (state, { payload }) => {
        state.userInfo = payload.data
      }
    )
    builder.addMatcher(
      isAnyOf(userServiceNew.endpoints.getMe.matchFulfilled),
      (state, { payload }) => {
        state.userInfoNew = payload
      }
    )
    builder.addMatcher(
      isAnyOf(userServiceNew.endpoints.getCompany.matchFulfilled),
      (state, { payload }) => {
        state.company = payload
      }
    )

    builder.addMatcher(
      isAnyOf(userServiceNew.endpoints.updateUserInfo.matchFulfilled),
      (state, { payload }) => {
        state.userInfoNew = { ...state.userInfoNew, ...payload }
      }
    )
    builder.addMatcher(
      isAnyOf(userServiceNew.endpoints.updateUserCountry.matchFulfilled),
      (state, { meta }) => {
        const country = meta.arg.originalArgs.country
        state.userInfoNew = { ...state.userInfoNew, country }
      }
    )

    builder.addMatcher(
      isAnyOf(userServiceNew.endpoints.getSettings.matchFulfilled),
      (state, { payload }) => {
        state.settings = payload
      }
    )

    builder.addMatcher(
      userService.endpoints.getShippingAddresses.matchFulfilled,
      (state, { payload }) => {
        state.shippingAddresses = payload.data
      }
    )

    builder.addMatcher(
      userService.endpoints.getOneShippingAddress.matchFulfilled,
      (state, { payload }) => {
        state.shippingDetail = payload.data
      }
    )

    builder.addMatcher(
      userService.endpoints.getBillingAddress.matchFulfilled,
      (state, { payload }) => {
        state.billingAddress = payload.data
      }
    )
  }
})
export const { deleteShippingAddress, clearCustomerInfor } = userSlice.actions
export const userReducer = userSlice.reducer
