import { useEffect, useRef, useState } from "react"
import AbandonedCartItem from "../AbandonedCartItem/AbandonedCartItem"
import "./AbandonedListCart.scss"
import { Quote, useGetListQuoteQuery } from "@/services/apiDigifabster/quote"
import { Loading } from "@/components"
import { useCurrency } from "@/hooks/useCurrency"

const limit = 10

function AbandonedCart() {
  const listInnerRef = useRef<HTMLDivElement>(null)
  const [isFetching, setIsFetching] = useState<boolean>(false)
  const [quantities, setQuantities] = useState<Record<number, number>>({})
  const [hasmore, setHasMore] = useState<boolean>(true)
  const [offset, setOffset] = useState<number>(0)
  const [item, setItem] = useState<Quote[]>([])

  const { refetch: fetchList } = useGetListQuoteQuery({
    limit,
    offset,
    ordering: "-date",
    status__in: ["initial"],
    status__not__in: []
  })

  const { refetch: fetchAmount, isLoading: isLoadingQuantity } =
    useGetListQuoteQuery({
      limit,
      offset,
      ordering: "-date",
      status__in: ["initial"],
      status__not__in: [],
      expand: ["line_items"]
    })

  const fetchData = async () => {
    setIsFetching(true)
    const res = await fetchList()

    const results = res.data?.results ?? []
    if (results.length > 0) {
      setItem((prevItems) => [...prevItems, ...results])
    } else {
      setHasMore(false)
    }
    setIsFetching(false)
  }

  const fetchQuantity = async () => {
    if (isFetching) return
    try {
      const res = await fetchAmount()
      const results = res.data?.results ?? []
      if (results.length > 0) {
        const newQuantities = results.reduce((acc, item) => {
          acc[item.id] = item.line_items?.length || 0
          return acc
        }, {} as Record<number, number>)
        setQuantities((prev) => ({
          ...prev,
          ...newQuantities
        }))
      } else {
        setHasMore(false)
      }
    } catch (error) {
      setIsFetching(false)
    }
  }

  const loadData = async () => {
    await fetchData()
    await fetchQuantity()
  }

  useEffect(() => {
    loadData()
  }, [offset])

  const fetchMoreData = async () => {
    if (hasmore && !isFetching) {
      setOffset((prevOffset) => prevOffset + limit)
    }
  }

  const { currency } = useCurrency()
  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current
      if (scrollTop + clientHeight >= scrollHeight) {
        if (hasmore && !isFetching) {
          fetchMoreData()
        }
      }
    }
  }

  useEffect(() => {
    const currentRef = listInnerRef.current
    if (currentRef) {
      currentRef.addEventListener("scroll", onScroll)
    }
    return () => {
      if (currentRef) {
        currentRef.removeEventListener("scroll", onScroll)
      }
    }
  }, [item, hasmore, isFetching])

  return (
    <div className="abandon-cart-container">
      <div className="abandon-cart-banner">
        <p>
          <span>Pick Up Where you Left Off</span>
        </p>
      </div>
      <div className="abandon-cart" ref={listInnerRef}>
        {item.map((item) => (
          <AbandonedCartItem
            currency={currency}
            item={item}
            key={item.id}
            quantity={quantities[item.id]}
            isLoadingQuantity={isLoadingQuantity}
          ></AbandonedCartItem>
        ))}
        {isFetching && (
          <div
            className="loading-more"
            style={{ height: !item.length ? "500px" : "" }}
          >
            <Loading></Loading>
          </div>
        )}
      </div>
    </div>
  )
}

export default AbandonedCart
