import {
  REGEX_EMAIL,
  REGEX_ONLY_NUMBER,
  REGEX_PHONE_NUMBER_DASH,
  REQUIRED_ERROR,
  REQUIRED_ERROR_FIELD
} from "@/constants/common.constant"
import { IState } from "@/interfaces/common.interface"
import { mixed, object, string } from "yup"

export const DELIVERY_SCHEMA = object({
  name: string().required(REQUIRED_ERROR_FIELD()),
  surname: string().required(REQUIRED_ERROR_FIELD()),
  phoneNumber: string()
    .required(REQUIRED_ERROR_FIELD())
    .test("phone-number-max", "Phone number must be 10 digits", (value) => {
      return value ? value.replace(/[-\s]/g, "").length === 10 : false
    })
    .test("phone-number-format", "Invalid format", (value) => {
      return value ? REGEX_PHONE_NUMBER_DASH.test(value) : true
    }),
  email: string()
    .required(REQUIRED_ERROR_FIELD())
    .test("contains-at-symbol", "Invalid format", (value) => {
      const splitEmails = value.split(/\s*,\s*/).map((email) => email.trim())
      return splitEmails.length
        ? splitEmails.every((a) => a.match(REGEX_EMAIL))
        : false
    }),
  company: string(),
  addressLine1: string().required(REQUIRED_ERROR_FIELD()),
  addressLine2: string(),
  city: string().required(REQUIRED_ERROR_FIELD()),
  state: mixed<IState>()
    .required(REQUIRED_ERROR_FIELD())
    .test("required", REQUIRED_ERROR_FIELD(), (value) => {
      if (value) {
        return !!value.id || !!value.name
      }
      return true
    }),
  zipCode: string().required(REQUIRED_ERROR_FIELD()),
  country: mixed<IState>().required(REQUIRED_ERROR_FIELD())
})

export const SHIPPING_ADDRESS_SCHEMA = object({
  name: string(),
  surname: string(),
  phoneNumber: string()
    .required(REQUIRED_ERROR_FIELD())
    .test("phone-number-max", "Phone number must be 10 digits", (value) => {
      return value ? value.replace(/[-\s]/g, "").length === 10 : false
    })
    .test("phone-number-format", "Invalid format", (value) => {
      return value ? REGEX_PHONE_NUMBER_DASH.test(value) : true
    }),
  email: string()
    .required(REQUIRED_ERROR_FIELD())
    .test("contains-at-symbol", "Invalid format", (value) => {
      const splitEmails = value.split(/\s*,\s*/).map((email) => email.trim())
      return splitEmails.length
        ? splitEmails.every((a) => a.match(REGEX_EMAIL))
        : false
    }),
  company: string(),
  addressLine1: string().required(REQUIRED_ERROR_FIELD()),
  addressLine2: string(),
  city: string().required(REQUIRED_ERROR_FIELD()),
  state: mixed<IState>()
    .required(REQUIRED_ERROR_FIELD())
    .test("required", REQUIRED_ERROR_FIELD(), (value) => {
      if (value) {
        return !!value.id || !!value.name
      }
      return true
    }),
  zipCode: string().required(REQUIRED_ERROR_FIELD()),
  country: mixed<IState>().required(REQUIRED_ERROR_FIELD())
})
