import * as React from "react"
import { ExclamationCircleOutlined } from "@ant-design/icons"
import "./ShippingContainer.scss"

interface IShippingContainerProps {
  children: React.ReactNode
}
const ShippingContainer: React.FC<IShippingContainerProps> = ({ children }) => {
  return (
    <div className="shipping-wrapper">
      <p className="shipping-wrapper-title">Shipping Address</p>
      <div className="shipping-hint">
        <ExclamationCircleOutlined
          color="var(--bg-gray-stronger)"
          width={16}
          height={16}
        />
        <p>
          The following addresses will be used on the checkout page as default
          delivery and shipping addresses
        </p>
      </div>
      <div className="shipping-container">{children}</div>
    </div>
  )
}
export default ShippingContainer
