import { useEffect, useState } from "react"
import { RootState } from "@/store"
import { IUserStore } from "@/store/user"
import { useSelector } from "react-redux"
import { Button } from "@/components"
import { ButtonHierarchy } from "@/enums/common.enum"
import BillingAddressContainer from "./components/BillingAddressContainer/BillingAddressContainer"
import BillingAddressForm from "./components/BillingAddressForm/BillingAddressForm"
import BillingAddressDetail from "./components/BillingAddressDetail/BillingAddressDetail"

function BillingAddressPage() {
  const { userInfoNew } = useSelector<RootState, IUserStore>((s) => s.user)
  const hasDeliveryAddresses = !!userInfoNew.billing_addresses.length

  const [isEdit, setIsEdit] = useState<boolean>(() => !hasDeliveryAddresses)
  return (
    <BillingAddressContainer>
      {isEdit ? (
        <BillingAddressForm setEdit={setIsEdit}></BillingAddressForm>
      ) : (
        <BillingAddressDetail></BillingAddressDetail>
      )}
      {!isEdit && (
        <div className="shipping-container-btn">
          <Button
            hierarchy={ButtonHierarchy.OUTLINE}
            onClick={() => setIsEdit(true)}
            customClassName="shipping-container-btn-edit"
          >
            Edit
          </Button>
        </div>
      )}
    </BillingAddressContainer>
  )
}

export default BillingAddressPage
